// import { writable } from 'svelte/store';
// in other "store" files, we use the writable store to create a store,
// but this is just a subscription to a Firebase query atm...
import { db } from '../firebase';
import { onSnapshot, doc } from 'firebase/firestore';
import { userStore } from './stores';

function createUserStore(userId) {
  const unsubscribe = onSnapshot(
    doc(db, '__users_collection__', userId),
    (doc) => {
      userStore.set(doc.data());
    }
  );
  return unsubscribe;
}

export { createUserStore };
