import { db } from '../firebase';
import { getDoc, doc } from 'firebase/firestore';

export const getBadges = async () => {
  // fetch it from the database
  const badgesRef = doc(db, '__meta_collection__', 'season', 'badges', 'all');
  const badgesDoc = await getDoc(badgesRef);
  if (!badgesDoc.exists()) return {};
  let badgesData = badgesDoc.data();

  return badgesData;
};
