/**
 * transformData
 *
 * @param {Object} roundsData - An object keyed by round number (or another identifier).
 *                              Each value is an object containing round-specific data.
 * @param {string[]} keysArray - An array of property names to extract from each round's data.
 * @param {number[]} rounds    - An array of round numbers (in ascending order) that should
 *                               appear in the output arrays.
 *
 * @returns {Object}           - An object whose keys are those in `keysArray`, each mapped
 *                               to an array of length = `rounds.length`.
 *                               - If round data is missing for a past round, the value is `0`.
 *                               - If round data is missing for a future round, the value is `null`.
 *                               - Otherwise, it's the actual value from `roundsData`.
 *
 * @example
 * // Example usage:
 * const data = {
 *   "1": { endOfRoundLadderPosition: 2, netWorth: -50 },
 *   "2": { endOfRoundLadderPosition: 4, netWorth: -10 }
 * };
 * const keys = ["endOfRoundLadderPosition", "netWorth"];
 * const allRounds = [1, 2, 3, 4];
 *
 * // '3' and '4' are future rounds (no data yet), '1' and '2' exist in the data.
 * // The function will produce:
 * // {
 * //   endOfRoundLadderPosition: [2, 4, null, null],
 * //   netWorth:                 [-50, -10, null, null]
 * // }
 *
 * // If the user had missed round 1 (not in the data) but '1' <= maxDataRound (2),
 * // that entry would be '0' instead of 'null'.
 */
export function transformDataForChart(roundsData, keysArray, rounds) {
  // Initialize the result object with arrays for each key
  const result = {};
  for (const key of keysArray) {
    result[key] = [];
  }

  // Determine the maximum round we actually have data for (to differentiate "missed" vs "future")
  const roundsInData = Object.keys(roundsData).map(Number);
  const maxDataRound = roundsInData.length ? Math.max(...roundsInData) : 0; // If no data at all, treat maxDataRound as 0

  // Iterate through all rounds in `rounds` (user-specified full list)
  for (const round of rounds) {
    const roundData = roundsData[round];

    // For each key, push the appropriate value
    for (const key of keysArray) {
      if (roundData && Object.prototype.hasOwnProperty.call(roundData, key)) {
        // ensure the value being pushed is a number
        const value = Number(roundData[key]);
        result[key].push(value);
      } else {
        // No data for this round/key
        // NOTE: originall this was <= then changed to <
        if (round < maxDataRound) {
          // Round is considered "missed" → use 0
          result[key].push(-1);
        } else {
          // Round is in the future → use null
          result[key].push(null);
        }
      }
    }
  }
  return result;
}
