<script>
  import { getEscapedSVG } from '../utils/formatting';
  export let padding = '0px 0px';
  export let margin = '0px';
  export let dropShadow = 'md'; //"drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))";
  export let backgroundColor = '#fff';
  export let borderColor = 'white';
  export let dropShadowColor = 'grey';
  let dropColor;

  switch (borderColor) {
    case 'yellow':
      borderColor = 'rgb(254,222,71)';
      dropColor = 'rgba(254,222,71,0.5)';
      break;
    case 'red':
      borderColor = 'rgb(235,87,87)';
      break;
    case 'green':
      borderColor = 'rgb(149,214,58)';
      break;
    case 'blue':
      borderColor = 'rgb(75,154,231)';
      break;
    case 'grey':
      borderColor = 'rgb(240,240,240)';
      dropColor = 'rgba(0,0,0,0.2)';
      break;
    case 'white':
      borderColor = 'rgb(255,255,255)';
      break;
    case 'darkGrey':
      borderColor = 'rgb(40,40,40)';
      break;
    default:
      borderColor = 'rgb(256,256,256)';
  }

  switch (dropShadowColor) {
    case 'yellow':
      dropColor = 'rgba(254,222,71,0.5)';
      break;
    case 'red':
      dropColor = 'rgba(235,87,87,0.5)';
      break;
    case 'green':
      dropColor = 'rgba(149,214,58,0.5)';
      break;
    case 'blue':
      dropColor = 'rgba(75,154,231,0.5)';
      break;
    case 'grey':
      dropColor = 'rgba(0,0,0,0.2)';
      break;
    case 'white':
      dropColor = 'rgba(0,0,0,0.2)';
      break;
  }

  $: escapedSVG = getEscapedSVG(borderColor);

  $: shadow = getShadow(dropShadow);

  function getShadow(dropShadow) {
    if (dropShadow === 'md') return `0px 4px 6px ${dropColor}`;
    else if (dropShadow === 'lg') return `0px 10px 15px ${dropColor}`;
    else if (dropShadow === 'xl') return `0px 20px 25px ${dropColor}`;
    else if (dropShadow === '2xl') return `0px 25px 50px ${dropColor}`;
    else if (dropShadow === 'inner') return `inset 0px 2px 4px ${dropColor}`;
    else if (dropShadow === 'none') return `0px 0px 0px ${dropColor}`;
    else return `0px 0px 0px ${dropColor}`;
  }
</script>

<div id="border-container">
  <div
    id="thick-border"
    style="--padding: {padding}; --background-color: {backgroundColor}; --margin: {margin}; --dropShadow: {shadow}; --border-color: url({escapedSVG});"
  >
    <slot />
  </div>
</div>

<style>
  #border-container {
    position: relative;
  }

  #thick-border {
    border: black solid 2px;
    background-color: var(--background-color);
    border-image-slice: 3;
    border-image-width: 3;
    border-image-repeat: stretch;
    border-image-source: var(--border-color);
    border-image-outset: 2;
    padding: var(--padding);
    margin: var(--margin);
    box-shadow: var(--dropShadow);
    /* filter: var(--dropShadow); */
  }

  #thick-border {
    transition: box-shadow 0.2s ease;
  }
</style>
