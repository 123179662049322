import { getAuth, signOut } from 'firebase/auth';

export const signOutUser = function () {
  const auth = getAuth();
  return new Promise((resolve) => {
    signOut(auth)
      .then(() => {
        // console.log("Signed out?")
        resolve(false);
        // Sign-out successful.
      })
      .catch((error) => {
        console.log('Signout error', error);
        // An error happened.
      });
  });
};
