<script>
  export let matchData;
  // export let matchId;
  // export let userTips;
  export let loading;
  export let tipped;
  import TeamPanel from './TeamPanel.svelte';
  import { formatDate } from '../utils/dates';
  import Badge from './Badge.svelte';
  import Icon from './Icon.svelte';
  import Flex from './Flex.svelte';
  import { slangTeams } from '../utils/teams';
  const winnerMessages = ['winner', 'yaaaay', 'fluke', 'got it'];
  const loserMessages = [
    'wrong',
    'loser',
    'nice try',
    'unlucky',
    'bah bow',
    'nope',
    'naaah',
    'BLNT',
  ];
  const notTippedMessages = [
    'not tipped',
    'have a go',
    'toss a coin',
    'just guess',
  ];
  let matchPassedMessages = ['missed it', 'too late', 'too slow'];
  if (matchData.winner) {
    if (matchData.winner === 'draw') {
      matchPassedMessages = ['match drawn'];
    } else {
      matchPassedMessages = [slangTeams[matchData.winner] + ' won'];
    }
  }
  const notOpenYetMessages = ['not yet', 'no odds'];
  console.log('matchData', matchData);
</script>

<div class="main-col">
  <Flex margin="2px 0px 5px 0px" justify="center">
    <span class="small-text">
      {formatDate(matchData.starttime)},
      {matchData.venue ?? 'TBC'}
    </span>
  </Flex>
  <Flex>
    <TeamPanel
      teamName={matchData.hometeam}
      teamOdds={matchData.hteam_odds}
      {tipped}
    />
    <div class="match-info">
      <div class="message-text">
        {#if loading}
          <sl-spinner />
        {:else if tipped}
          {#if tipped.winner === true}
            <Badge colour="success"
              ><Icon height="12px" margin="0px 5px 0px 0px" name="coin_check" />
              {winnerMessages[
                Math.floor(Math.random() * winnerMessages.length)
              ]}</Badge
            >
          {:else if tipped.winner === false && tipped?.isDraw === true}
            <Badge colour="primary"
              ><Icon
                height="12px"
                margin="0px 5px 0px 0px"
                name="coin_draw"
              />refund</Badge
            >
          {:else if tipped.winner === false}
            <Badge colour="warning"
              ><Icon height="12px" margin="0px 5px 0px 0px" name="coin_cross" />
              {loserMessages[
                Math.floor(Math.random() * loserMessages.length)
              ]}</Badge
            >
          {:else}
            <Badge colour="primary"
              ><Icon height="12px" margin="0px 5px 0px 0px" name="clock" /> tipped</Badge
            >
          {/if}
        {:else if !tipped && matchData.ateam_odds && matchData.hteam_odds}
          {#if matchData.starttime > new Date().getTime()}
            <Badge colour="light"
              >{notTippedMessages[
                Math.floor(Math.random() * notTippedMessages.length)
              ]}</Badge
            >
            <!-- Here, if matchData.winner !== undefined || null, then there is a winner but the user
            didn't make a tip, so we can say what the winner was -->
          {:else}
            <Badge colour="light"
              >{#if !matchData.winner}<Icon
                  height="12px"
                  margin="0px 5px 0px 0px"
                  name="exclamation_dark"
                  filter="invert(76%) sepia(0%) saturate(1274%) hue-rotate(203deg) brightness(83%) contrast(80%)"
                />{/if}{matchPassedMessages[
                Math.floor(Math.random() * matchPassedMessages.length)
              ]}</Badge
            >
          {/if}
        {/if}
        {#if !matchData.ateam_odds || !matchData.hteam_odds}
          <Badge colour="neutral"
            ><Icon
              height="12px"
              margin="0px 5px 0px 0px"
              name="exclamation_dark"
            />
            {notOpenYetMessages[
              Math.floor(Math.random() * notOpenYetMessages.length)
            ]}</Badge
          >
        {/if}
      </div>
    </div>
    <TeamPanel
      teamName={matchData.awayteam}
      teamOdds={matchData.ateam_odds}
      {tipped}
    />
  </Flex>
</div>

<style>
  .main-col {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0px 2px;
    /* height: 135px; */
  }

  .match-info {
    /* padding-top: 10px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .small-text {
    font-family: 'Source Code Pro', monospace;
    text-align: center;
    font-size: 11px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 300px;
  }

  .message-text {
    font-family: 'Source Code Pro', monospace;
    text-align: center;
    font-size: 16px;
    /* margin-top: 5px; */
  }
</style>
