<script>
  import { fly } from 'svelte/transition';
  import { circIn, circOut } from 'svelte/easing';
  import { onMount, onDestroy } from 'svelte';

  // Props
  export let repeat = true;
  export let duration = 500; // Duration of each animation phase in milliseconds
  export let repeatDelay = 1000; // Delay between each loop in milliseconds

  // Animation parameters
  let y = 50; // Vertical offset for entering animation
  let outY = 50; // Vertical offset for exiting animation
  let easeIn = circIn;
  let easeOut = circOut;
  let delayIncrement = 50; // Delay between each letter's animation in milliseconds

  // Visibility flag
  let visible = false;

  // Control flag for stopping the loop
  let stopLoop = false;

  // Helper function to create delays
  function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  // Animation loop function
  async function animateLoop() {
    while (!stopLoop && repeat) {
      // Start entering animation
      visible = true;
      const enterDuration = delayIncrement * 9 + duration;
      await delay(enterDuration);

      // Start exiting animation
      visible = false;
      const exitDuration = delayIncrement * 9 + duration;
      await delay(exitDuration + repeatDelay);
    }
  }

  // Initialize animation on mount
  onMount(() => {
    visible = true;
    if (repeat) {
      animateLoop();
    }
  });

  // Clean up on destroy
  onDestroy(() => {
    stopLoop = true;
  });
</script>

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 200">
  <defs>
    <style>
      .cls-1 {
        fill: #231f20;
      }
      .cls-2 {
        fill: #fff;
        stroke: #231f20;
        stroke-miterlimit: 10;
        stroke-width: 8px;
      }
    </style>
  </defs>

  <!-- Letter 1 -->
  {#if visible}
    <g
      in:fly={{
        y,
        duration,
        easeIn,
        delay: delayIncrement * 0,
      }}
      out:fly={{
        y: -outY,
        duration,
        easeOut,
        delay: delayIncrement * 0,
      }}
    >
      <path
        class="cls-1"
        d="m17.41,183.3V32.39h150.91v150.91H17.41Zm90.54-30.18V62.57h-30.18v90.54h30.18Z"
      />
      <path
        class="cls-2"
        d="m4,169.72V18.82h150.91v150.91H4Zm90.54-30.18V49h-30.18v90.54h30.18Z"
      />
    </g>
  {/if}

  <!-- Letter 2 -->
  {#if visible}
    <g
      in:fly={{
        y,
        duration,
        easeIn,
        delay: delayIncrement * 1,
      }}
      out:fly={{
        y: -outY,
        duration,
        easeOut,
        delay: delayIncrement * 1,
      }}
    >
      <path
        class="cls-1"
        d="m198.5,183.3V32.39h120.72v30.18h30.18v90.54h-30.18v30.18h-120.72Zm60.36-30.18V62.57h-30.18v90.54h30.18Z"
      />
      <path
        class="cls-2"
        d="m185.09,169.72V18.82h120.72v30.18h30.18v90.54h-30.18v30.18h-120.72Zm60.36-30.18V49h-30.18v90.54h30.18Z"
      />
    </g>
  {/if}

  <!-- Letter 3 -->
  {#if visible}
    <g
      in:fly={{
        y,
        duration,
        easeIn,
        delay: delayIncrement * 2,
      }}
      out:fly={{
        y: -outY,
        duration,
        easeOut,
        delay: delayIncrement * 2,
      }}
    >
      <path
        class="cls-1"
        d="m379.58,183.3V32.39h120.72v30.18h30.18v90.54h-30.18v30.18h-120.72Zm60.36-30.18V62.57h-30.18v90.54h30.18Z"
      />
      <path
        class="cls-2"
        d="m366.17,169.72V18.82h120.72v30.18h30.18v90.54h-30.18v30.18h-120.72Zm60.36-30.18V49h-30.18v90.54h30.18Z"
      />
    </g>
  {/if}

  <!-- Letter 4 -->
  {#if visible}
    <g
      in:fly={{
        y,
        duration,
        easeIn,
        delay: delayIncrement * 3,
      }}
      out:fly={{
        y: -outY,
        duration,
        easeOut,
        delay: delayIncrement * 3,
      }}
    >
      <path
        class="cls-1"
        d="m711.58,183.3V62.57h-30.18v-30.18h150.91v30.18h-30.18v120.72h-90.54Z"
      />
      <path
        class="cls-2"
        d="m698.16,169.72V49h-30.18v-30.18h150.91v30.18h-30.18v120.72h-90.54Z"
      />
    </g>
  {/if}

  <!-- Letter 5 -->
  {#if visible}
    <g
      in:fly={{
        y,
        duration,
        easeIn,
        delay: delayIncrement * 4,
      }}
      out:fly={{
        y: -outY,
        duration,
        easeOut,
        delay: delayIncrement * 4,
      }}
    >
      <path
        class="cls-1"
        d="m862.48,183.3v-30.18h30.18V62.57h-30.18v-30.18h150.91v30.18h-30.18v90.54h30.18v30.18h-150.91Z"
      />
      <path
        class="cls-2"
        d="m849.07,169.72v-30.18h30.18V49h-30.18v-30.18h150.9v30.18h-30.18v90.54h30.18v30.18h-150.9Z"
      />
    </g>
  {/if}

  <!-- Letter 6 -->
  {#if visible}
    <g
      in:fly={{
        y,
        duration,
        easeIn,
        delay: delayIncrement * 5,
      }}
      out:fly={{
        y: -outY,
        duration,
        easeOut,
        delay: delayIncrement * 5,
      }}
    >
      <path
        class="cls-1"
        d="m1043.57,183.3V32.39h150.91v120.72h-90.54v30.18h-60.36Zm90.54-60.36v-60.36h-30.18v60.36h30.18Z"
      />
      <path
        class="cls-2"
        d="m1030.16,169.72V18.82h150.91v120.72h-90.54v30.18h-60.36Zm90.54-60.36v-60.36h-30.18v60.36h30.18Z"
      />
    </g>
  {/if}

  <!-- Letter 7 -->
  {#if visible}
    <g
      in:fly={{
        y,
        duration,
        easeIn,
        delay: delayIncrement * 6,
      }}
      out:fly={{
        y: -outY,
        duration,
        easeOut,
        delay: delayIncrement * 6,
      }}
    >
      <path
        class="cls-1"
        d="m1224.65,183.3V32.39h150.91v120.72h-90.54v30.18h-60.36Zm90.54-60.36v-60.36h-30.18v60.36h30.18Z"
      />
      <path
        class="cls-2"
        d="m1211.24,169.72V18.82h150.91v120.72h-90.54v30.18h-60.36Zm90.54-60.36v-60.36h-30.18v60.36h30.18Z"
      />
    </g>
  {/if}

  <!-- Letter 8 -->
  {#if visible}
    <g
      in:fly={{
        y,
        duration,
        easeIn,
        delay: delayIncrement * 7,
      }}
      out:fly={{
        y: -outY,
        duration,
        easeOut,
        delay: delayIncrement * 7,
      }}
    >
      <path
        class="cls-1"
        d="m1405.74,183.3v-30.18h30.18V62.57h-30.18v-30.18h150.91v30.18h-30.18v90.54h30.18v30.18h-150.91Z"
      />
      <path
        class="cls-2"
        d="m1392.33,169.72v-30.18h30.18V49h-30.18v-30.18h150.91v30.18h-30.18v90.54h30.18v30.18h-150.91Z"
      />
    </g>
  {/if}

  <!-- Letter 9 -->
  {#if visible}
    <g
      in:fly={{
        y,
        duration,
        easeIn,
        delay: delayIncrement * 8,
      }}
      out:fly={{
        y: -outY,
        duration,
        easeOut,
        delay: delayIncrement * 8,
      }}
    >
      <path
        class="cls-1"
        d="m1586.83,183.3V32.39h90.54v90.54h30.18V32.39h30.18v150.91h-90.54v-90.54h-30.18v90.54h-30.18Z"
      />
      <path
        class="cls-2"
        d="m1573.42,169.72V18.82h90.54v90.54h30.18V18.82h30.18v150.91h-90.54v-90.54h-30.18v90.54h-30.18Z"
      />
    </g>
  {/if}

  <!-- Letter 10 -->
  {#if visible}
    <g
      in:fly={{
        y,
        duration,
        easeIn,
        delay: delayIncrement * 9,
      }}
      out:fly={{
        y: -outY,
        duration,
        easeOut,
        delay: delayIncrement * 9,
      }}
    >
      <path
        class="cls-1"
        d="m1767.91,183.3V32.39h150.91v30.18h-90.54v90.54h30.18v-60.36h60.36v90.54h-150.91Z"
      />
      <path
        class="cls-2"
        d="m1754.5,169.72V18.82h150.91v30.18h-90.54v90.54h30.18v-60.36h60.36v90.54h-150.91Z"
      />
    </g>
  {/if}
</svg>

<style>
  svg {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }
</style>
