<script>
  export let width = '100%';
  export let height = '100%';
  export let margin = '0px 0px';
  export let padding = '0px 0px';
  export let maxWidth = 'none';
  export let display = 'block';
  export let justify = 'center';
  export let alignItems = 'center';
</script>

<div
  style="--justify: {justify}; --alignItems: {alignItems}; --display: {display}; --width: {width}; --height: {height}; --padding: {padding}; --margin: {margin}; --max-width: {maxWidth}"
>
  <slot />
</div>

<style>
  div {
    width: var(--width);
    height: var(--height);
    margin: var(--margin);
    padding: var(--padding);
    max-width: var(--max-width);
    display: var(--display);
    justify-content: var(--justify);
    align-items: var(--alignItems);
  }
</style>
