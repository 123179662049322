<script>
  import {
    Heading,
    Icon,
    VerticalSpacer,
    PageContainer,
  } from '../components/ui';
  const sport = '__sport__';
</script>

<PageContainer>
  <VerticalSpacer height="20px" />
  <Heading>Odd Tipping sports</Heading>
  <VerticalSpacer height="10px" />
  <p>
    Odd Tipping is available for two of Australia's top footy comps – Aussie
    Rules and Rugby League.
  </p>
  <p>Maybe there's more to come, who bloody knows.</p>
  <p>Follow the links to get where you wanna go:</p>
  <p>
    <a href="https://oddtipping.com.au" rel="noopener"
      ><Icon margin="0px 5px 5px 0px" name="arrow" height="10px" />Play Aussie
      Rules tipping</a
    >
    {#if sport === 'afl'}
      (this is where you already are)
    {/if}
  </p>
  <p>
    <a href="https://nrl.oddtipping.com.au" rel="noopener"
      ><Icon margin="0px 5px 5px 0px" name="arrow" height="10px" />Play Rugby
      League tipping</a
    >
    {#if sport === 'nrl'}
      (this is where you already are)
    {/if}
  </p>
  <p>
    Oh, one important thing – if you're playing Odd Tipping for more than one
    sport, you can login using the same email and password everywhere.
  </p>
  <p>
    Just remember to pick a username you like, because currently you can't
    change it. Yeah, yeah, maybe someday we'll add that feature.
  </p>
  <VerticalSpacer height="20px" />
</PageContainer>

<style>
  p {
    font-family: 'Source Code Pro', monospace;
    font-size: 16px;
  }
</style>
