<script>
  import { onDestroy } from 'svelte';
  import { currentPath } from '../stores/pathnameStore';
  import { analytics } from '../firebase.js';
  import { logEvent } from 'firebase/analytics';

  // Function to log page views
  function logPageView(path) {
    console.log('Screen view:', path);
    logEvent(analytics, 'screen_view', { page_path: path });
  }

  // Subscribe to currentPath store
  const unsubscribe = currentPath.subscribe((path) => {
    logPageView(path);
  });

  onDestroy(() => {
    // Clean up the subscription when the component is destroyed
    unsubscribe();
  });
</script>
