import { db } from '../firebase/';
import { doc, updateDoc } from 'firebase/firestore';

export const updateUserSettings = async (userId, settings) => {
  const userRef = doc(db, '__users_collection__', userId);
  try {
    await updateDoc(userRef, { settings: settings });
    return { success: true };
  } catch (error) {
    console.error('Failed to update user settings:', error);
    return { success: false };
  }
};
