<script>
  export let id;
  import {
    getLeagueLeaderboard,
    updateLeagueLeaderboard,
  } from '../utils/leagues';
  import { leagueLeaderboardStore, addToast } from '../stores/stores';
  import DataTable from '../components/DataTable.svelte';
  import { leaveLeague } from '../utils/leagues';
  import {
    Heading,
    Button,
    Flex,
    Text,
    LoadingText,
    VerticalSpacer,
    Divider,
    Icon,
    PageContainer,
  } from '../components/ui';
  import RoundSelector from '../components/RoundSelector.svelte';
  let leaderboardData;
  let leagueData;
  let pendingTips;
  let users;
  let loading = true;
  let loadingData = true;
  import { navigate } from 'svelte-routing';
  let dialog;
  import { userStore } from '../stores/stores';
  import { joinLeague } from '../utils/leagues';
  import TextInput from '../components/TextInput.svelte';
  import Box from '../components/Box.svelte';
  import { slangTeams } from '../utils/teams';

  const urlParams = new URLSearchParams(window.location.search);
  const round = urlParams.get('round');
  let selectedRound = parseInt(round) || 'overall';

  // $: userRank = leaderboardData?.findIndex((i) => i.id === $userStore.id) + 1;

  $: userIsInLeague = leagueData?.users.includes($userStore.id);

  if (id && $userStore) {
    loadingData = true;
    console.log('getting league stuff!');
    getLeagueLeaderboard(id, selectedRound)
      .then(() => {
        loadingData = false;
      })
      .catch((error) => {
        console.log(error);
        loadingData = false;
        alert('There is no league with that ID');
        navigate(`/leagues`);
      });
  } else {
    navigate(`/?joinLeague=${id}`);
  }

  leagueLeaderboardStore.subscribe((value) => {
    if (value) loading = false;
    leaderboardData = value?.leaderboardData.sort(
      (a, b) => b.netWorth - a.netWorth
    );
    leagueData = value?.leagueData;
    pendingTips = value?.tips;
    users = value?.users;
  });

  const prettyMatchTitle = (matchTitle) => {
    const parts = matchTitle.split(' ');
    return `${slangTeams[parts[0]]} vs ${slangTeams[parts[2]]}`;
  };
</script>

<!-- leagueData?.name icon: users -->
{#if !loadingData}
  <PageContainer>
    <VerticalSpacer height="20px" />
    <Flex justify="flex-start">
      <Icon margin="0px 10px 0px 0px" name="users" />
      <Heading>{leagueData?.name}</Heading>
    </Flex>

    <VerticalSpacer height="30px" />
    <Flex justify="flex-start" alignItems="center">
      <Icon margin="0px 10px 0px 0px" height="16px" name="trophy" />
      <Text fontWeight="600">{'rankings'}</Text>
    </Flex>
    <RoundSelector
      extraRounds={['overall']}
      {selectedRound}
      onRoundSelect={async (round) => {
        loading = true;
        selectedRound = round;
        window.history.replaceState({}, '', '?' + urlParams.toString());
        const data = await updateLeagueLeaderboard(round, leagueData.users);
        leaderboardData = data;
        loading = false;
      }}
    />
    {#if leaderboardData}
      {#if selectedRound !== 'overall'}
        <DataTable
          data={leaderboardData}
          headers={['#', 'name', `round ${selectedRound}`, 'pending']}
          dataKeys={['rank', 'name', 'netWorth', 'pending']}
          dataPrefixes={[null, null, '$', '$']}
        />
      {:else}
        <DataTable
          data={leaderboardData}
          headers={['#', 'name', 'net worth', 'pending', 'form']}
          dataKeys={['rank', 'name', 'netWorth', 'pending', 'form']}
          dataPrefixes={[null, null, '$', '$']}
        />
      {/if}
    {:else if loading}
      <div class="loading-container">
        <LoadingText />
      </div>
    {:else}
      <Text>Something went wrong</Text>
    {/if}

    <VerticalSpacer height="30px" />
    <Flex margin="0px 10px" id={leaderboardData?.length}>
      {#if userIsInLeague}
        <sl-dialog no-header bind:this={dialog}>
          <Flex margin="10px 0px">
            <Text fontSize="16px" fontWeight="600">invite a mate</Text>
            <Icon name="cross" height="15px" onClick={() => dialog.hide()} />
          </Flex>
          <Flex direction="column">
            <Text fontSize="medium"
              >To add someone to this league, copy a league code or link and
              send it to them. To join, they'll need to input the join code or
              select 'join league' after visiting the link.</Text
            >
            <VerticalSpacer />
            <Text fontSize="large">option 1: share a link</Text>
            <TextInput value={window.location.href} />
            <VerticalSpacer />
            <Button
              primary
              fontSize="medium"
              iconRight="link"
              onClick={() => {
                navigator.clipboard.writeText(`${window.location.href}`);
                addToast({
                  message: 'copied join link',
                  type: 'info',
                  dismissible: true,
                  timeout: 2000,
                });
              }}>copy join link</Button
            >
            <VerticalSpacer height="20px" />
            <Text fontSize="large">option 2: share a join code</Text>
            <TextInput value={id} />
            <VerticalSpacer />
            <Button
              primary
              fontSize="medium"
              iconRight="copy"
              onClick={() => {
                navigator.clipboard.writeText(`${id}`);
                addToast({
                  message: 'copied join code',
                  type: 'info',
                  dismissible: true,
                  timeout: 2000,
                });
              }}>copy join code</Button
            >
          </Flex>
        </sl-dialog>

        <Flex direction="column">
          <Divider />
          <VerticalSpacer height="30px" />
          <Flex justify="flex-start" alignItems="center">
            <Icon margin="0px 10px 0px 0px" height="16px" name="clock" />
            <Text fontWeight="600">{'pending tips'}</Text>
          </Flex>
          {#if pendingTips && pendingTips.length > 0}
            <table>
              {#each pendingTips as pendingTip, i}
                {#if pendingTips[i - 1]?.title !== pendingTip.title}
                  <tr style="padding: 5px 0px 0px 5px !important;">
                    <td
                      colspan="2"
                      style="padding: 5px 0px 0px 5px !important;"
                    >
                      <Text fontWeight="300" fontSize="small"
                        >{prettyMatchTitle(pendingTip.title)}</Text
                      >
                    </td>
                  </tr>
                {/if}
                <tr>
                  <td>
                    <Text fontSize="13px"
                      >{users.find((u) => u.id === pendingTip.userId)
                        .name}</Text
                    >
                  </td>
                  <td class="td-center">
                    <Text fontSize="small"
                      >{slangTeams[pendingTip.tip]} (${pendingTip.stake
                        .toFixed(2)
                        .replace(/[.,]00$/, '')} @ {pendingTip.odds.toFixed(
                        2
                      )})</Text
                    >
                  </td>
                </tr>
              {/each}
            </table>
          {:else}
            <Text fontColor="grey">none atm.</Text>
          {/if}
          <VerticalSpacer height="30px" />
          <Divider />
          <VerticalSpacer height="30px" />
          <Flex justify="flex-start" alignItems="center">
            <!-- <Icon margin="0px 10px 0px 0px" height="16px" name="clock" /> -->
            <Text fontWeight="600">{'options'}</Text>
          </Flex>

          <Flex direction="column">
            <Text fontSize="13px">Invite friends to join this league</Text>
            <VerticalSpacer />
            <Flex>
              <Button
                primary
                fontSize="medium"
                iconRight="send"
                padding="5px 10px"
                onClick={() => {
                  dialog.show();
                }}>invite a mate</Button
              >
            </Flex>
            <VerticalSpacer height="30px" />
            <Text fontSize="13px"
              >Don't want to play with these people anymore? Leave this league
              (you can always join again later).</Text
            >
            <VerticalSpacer height="10px" />
            <Flex>
              <Button
                fontSize="medium"
                iconRight="leave"
                padding="5px 10px"
                onClick={async () => {
                  if (window.confirm('Do you really want to leave?')) {
                    await leaveLeague(id);
                    navigate(`/leagues`);
                  }
                }}>leave league</Button
              >
            </Flex>
          </Flex>
        </Flex>
      {:else}
        <Box>
          <Flex direction="column">
            <Text
              >joining a league means your wallet and tips will be visible to
              other members of the league.</Text
            >
            <VerticalSpacer />
            <Button
              fontSize="medium"
              primary
              borderColor="darkGrey"
              onClick={async () => {
                try {
                  await joinLeague(id);
                  getLeagueLeaderboard(id, 'overall');
                  addToast({
                    message: 'joined this league!',
                    type: 'success',
                    dismissible: true,
                    timeout: 3000,
                  });
                } catch (error) {
                  console.error('joinLeague error', error);
                  addToast({
                    message: 'something went wrong. try again',
                    type: 'error',
                    dismissible: true,
                    timeout: 3000,
                  });
                }
              }}>join league</Button
            >
          </Flex>
        </Box>
      {/if}
    </Flex>
  </PageContainer>
{:else}
  <div class="loading-container">
    <LoadingText />
  </div>
{/if}

<style>
  table {
    width: 100%;
  }
  td {
    padding: 0px 5px 5px 5px;
    text-align: left;
  }
  .td-center {
    text-align: center;
  }
  .loading-container {
    height: 80vh;
    width: 100%;
  }
</style>
