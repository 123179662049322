<script>
  import {
    Flex,
    Text,
    Heading,
    PageContainer,
    VerticalSpacer,
  } from '../components/ui';
</script>

<PageContainer>
  <Flex direction="column" alignItems="flex-start">
    <VerticalSpacer height="100px" />
    <Heading>🚨 Everything is bad!</Heading>
    <VerticalSpacer height="30px" />
    <Text>I've broken something... 🤦</Text>
    <VerticalSpacer height="30px" />
    <Text>Or something had broken... 🐛</Text>
    <VerticalSpacer height="30px" />
    <Text>Either way, I have NFI what's going on right now!</Text>
    <VerticalSpacer height="30px" />
    <Text>But...we should be back soon 🙏</Text>
  </Flex>
</PageContainer>
