<script>
  import { fly } from 'svelte/transition';
</script>

<div id="logo-container" style="--bg-width:85px">
  <div class="colour-row left-10" in:fly|global={{ x: -60, duration: 800 }} />
  <div class="colour-row" in:fly|global={{ x: -60, duration: 900 }} />
  <div class="colour-row left-10" in:fly|global={{ x: -60, duration: 700 }} />
  <div class="colour-row left-5" in:fly|global={{ x: -60, duration: 900 }} />
  <div class="colour-row left-10" in:fly|global={{ x: -60, duration: 700 }} />
  <img src="/images/android-chrome-72x72.png" alt="logo" />
</div>

<style>
  #logo-container {
    margin-left: -1px;
    width: var(--bg-width);
    height: 50px;
  }
  .colour-row {
    width: 100%;
    height: 10px;
    background-color: #fede47;
  }

  .left-10 {
    position: relative;
    right: 7.5px;
  }

  .left-5 {
    position: relative;
    right: 15px;
  }

  img {
    position: absolute;
    height: 50px;
    top: 0px;
    left: 10px;
  }

</style>
