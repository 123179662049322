import { writable } from "svelte/store";

export const fixtures = writable({});

export const loadingUser = writable(true);

export const currentRound = writable(null);

export const pageName = writable(null);

export const userStore = writable(null);

export const userCount = writable(null);

// export const selectedRoundStore = writable(null);

export const tipsStore = writable(null);

export const leagueLeaderboardStore = writable(null);

export const viewedUserStore = writable(null);

export const viewedUserTipsStore = writable([]);

export const viewedUserWalletStore = writable([]);

export const viewedUserWalletLastDoc = writable(null);

export const viewedUserTipsLastDoc = writable(null);

export const viewedUserId = writable(null);

export const toasts = writable([]);

export const crowdTips = writable({});

export const competitionStatus = writable(null);

export const rounds = writable(null);

export const overallLeaderboard = writable(null);

export const badges = writable(null);

export const blogPosts = writable([]);

export const userSigningIn = writable(false);

// TOASTS
export const addToast = (toast) => {
  // Create a unique ID so we can easily find/remove it
  // if it is dismissible/has a timeout.
  const id = Math.floor(Math.random() * 10000);

  // Setup some sensible defaults for a toast.
  const defaults = {
    id,
    type: "info",
    dismissible: true,
    timeout: 3000,
  };

  // Push the toast to the top of the list of toasts
  toasts.update((all) => [{ ...defaults, ...toast }, ...all]);

  // If toast is dismissible, dismiss it after "timeout" amount of time.
  if (toast.timeout) setTimeout(() => dismissToast(id), toast.timeout);
};

export const dismissToast = (id) => {
  toasts.update((all) => all.filter((t) => t.id !== id));
};
