<script>
  export let link = '';
  export let image = '';
  export let title = '';
  export let type = '';
  // export let description = ""; TODO
  export let published = '';
  import { Link } from 'svelte-routing';
  import { ThickBorder, Text, Heading, Image, VerticalSpacer } from './ui';
</script>

<ThickBorder padding="10px" borderColor="grey" margin="0px 0px 20px 0px">
  <Link to={link}>
    {#if image}
      <Image src={image} alt="blog" margin="0px 0px 10px 0px" />
    {/if}
    <Heading>{title}</Heading>
    {#if type}
      <div class="highlight">
        <Text>{type}</Text>
      </div>
      <VerticalSpacer height="10px" />
    {/if}
    <Text fontSize="14px" fontColor="#999"
      >{new Date(published).toDateString()}</Text
    >
  </Link>
</ThickBorder>

<style>
  .highlight {
    background-color: #ffde46;
    display: inline-block;
    padding: 0px 8px;
    border-radius: 8px;
  }
</style>
