<script>
  import { onDestroy } from 'svelte';
  import { currentPath } from '../stores/pathnameStore';
  import { navigate } from 'svelte-routing';
  import { Text, Icon } from '../components/ui';
  import { fade } from 'svelte/transition';

  let path;
  let historyStack = [];
  $: hideBackButton = historyStack.length <= 1;

  // Subscribe to the currentPath store
  const unsubscribe = currentPath.subscribe((newPath) => {
    if (historyStack[historyStack.length - 1] !== newPath) {
      historyStack = [...historyStack, newPath];
    }
    path = newPath;
  });

  function goBack() {
    if (historyStack.length > 1) {
      historyStack = [...historyStack.slice(0, -1)];
      const previousPath = historyStack[historyStack.length - 1];
      navigate(previousPath, { replace: true });
    }
  }

  onDestroy(() => {
    unsubscribe();
  });
</script>

{#if !hideBackButton}
  <div
    role="button"
    tabindex="0"
    transition:fade|global={{ delay: 50, duration: 250 }}
    class="back-button"
    on:click={goBack}
  >
    <Icon
      height="12px"
      margin="0px 5px 0px 0px"
      name="arrow"
      transform="rotate(180deg)"
    />
    <Text fontSize="13px">back</Text>
  </div>
{/if}

<style>
  .back-button {
    margin: 0px 0px 10px 10px;
    align-items: center;
    display: flex;
    cursor:
      url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAzElEQVRYR+2X0Q6AIAhF5f8/2jYXZkwEjNSVvVUjDpcrGgT7FUkI2D9xRfQETwNIiWO85wfINfQUEyxBG2ArsLwC0jioGt5zFcwF4OYDPi/mBYKm4t0U8ATgRm3ThFoAqkhNgWkA0jJLvaOVSs7j3qMnSgXWBMiWPXe94QqMBMBc1VZIvaTu5u5pQewq0EqNZvIEMCmxAawK0DNkay9QmfFNAJUXfgGgUkLaE7j/h8fnASkxHTz0DGIBMCnBeeM7AArpUd3mz2x3C7wADglA8BcWMZhZAAAAAElFTkSuQmCC)
        14 0,
      pointer;
  }
</style>
