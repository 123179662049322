import { writable } from 'svelte/store';

export const currentPath = writable(window.location.pathname);

function trackPathChanges() {
  // Save original pushState and replaceState for restoration
  const originalPushState = history.pushState;
  const originalReplaceState = history.replaceState;

  function updatePath() {
    currentPath.set(window.location.pathname);
  }

  // Wrap pushState and replaceState to track programmatic navigation
  history.pushState = function (...args) {
    originalPushState.apply(this, args);
    updatePath();
  };

  history.replaceState = function (...args) {
    originalReplaceState.apply(this, args);
    updatePath();
  };

  // Listen for back/forward browser navigation
  window.addEventListener('popstate', updatePath);

  // Cleanup function to restore original methods and remove listeners
  return () => {
    history.pushState = originalPushState;
    history.replaceState = originalReplaceState;
    window.removeEventListener('popstate', updatePath);
  };
}

// Automatically start tracking when the store is imported
const cleanup = trackPathChanges();

// Export cleanup in case it's needed elsewhere
export { cleanup };
