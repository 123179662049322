<script>
  import { getFunctions, httpsCallable } from 'firebase/functions';
  import { getMatchUserTipsSample } from '../utils/tips';
  import MatchInformation from './MatchInformation.svelte';
  import HorizontalSpacer from './HorizontalSpacer.svelte';
  import { addToast, crowdTips } from '../stores/stores';
  const functions = getFunctions();
  const submitTips = httpsCallable(functions, 'submitTips');
  import { userStore } from '../stores/stores';
  import { slangTeams } from '../utils/teams';
  import Message from './alerts/Message.svelte';
  import { tweened } from 'svelte/motion';
  import TeamPanel from './TeamPanel.svelte';
  import {
    Icon,
    Box,
    Text,
    Flex,
    VerticalSpacer,
    ThickBorder,
    Loading,
    Button,
  } from '../components/ui';

  export let matchData;
  export let userTips;
  export let docId;
  export let canTip = true;

  let error = false;
  let errorMessage = '';
  let tip;
  let stake;
  let tipIsHomeTeam;
  let matchId;
  let buttonState = 'is-disabled';
  let pastStartTime = false;
  $: selectedTipOdds = tip
    ? tipIsHomeTeam
      ? matchData.hteam_odds
      : matchData.ateam_odds
    : null;

  $: isWinner = userTips.find((tip) => tip.matchId === docId)?.winner;

  const potentialReturn = tweened(stake * selectedTipOdds || 0);
  $: {
    potentialReturn.set(stake * selectedTipOdds || 0);
  }

  const getCrowdTips = async (matchId) => {
    const matchWeightings = await getMatchUserTipsSample(matchId);
    if (!matchWeightings) return;
    // if (matchData.length === 0) return;
    // const matchWeightings = {
    //   hometeam:
    //     (matchData.filter((tip) => tip.homeTeam).length / matchData.length) *
    //     100,
    //   awayteam:
    //     (matchData.filter((tip) => !tip.homeTeam).length / matchData.length) *
    //     100,
    // };
    crowdTips.update((all) => ({ [matchId]: matchWeightings, ...all }));
  };

  let setButtonState = (team, tipStake, homeTeamFlag) => {
    let currentTime = new Date().getTime();
    if (matchData.starttime < currentTime) {
      pastStartTime = true;
    }
    error = false;
    tip = team;
    stake = tipStake;
    tipIsHomeTeam = homeTeamFlag;
    if (homeTeamFlag === undefined && tip !== null) {
      tipIsHomeTeam = tip === matchData.hometeam ? true : false;
    } else {
      tipIsHomeTeam = homeTeamFlag;
    }
    if (userTips.find((tip) => tip.matchId === matchData.doc_id)) {
      if (
        team === userTips.find((tip) => tip.matchId === matchData.doc_id).tip &&
        tipStake ===
          userTips.find((tip) => tip.matchId === matchData.doc_id).stake
      ) {
        buttonState = 'is-disabled';
      } else {
        buttonState = 'is-primary';
      }
    } else {
      buttonState = 'is-primary';
    }

    if (team === null || tipStake === null) {
      buttonState = 'is-disabled';
    }
  };
  let drawer;
  let loading = false;
  const openDrawer = (usersMatchTip, matchDataDoc) => {
    matchId = matchDataDoc.doc_id;
    // we'll only fetch the crowd tips once-ish
    if (!$crowdTips[matchId]) {
      getCrowdTips(matchId);
    }
    if (!usersMatchTip) {
      tip = null;
      stake = null;
      tipIsHomeTeam = null;
    } else {
      tip = usersMatchTip.tip;
      stake = usersMatchTip.stake;
      tipIsHomeTeam = usersMatchTip.homeTeam;
    }
    setButtonState(tip, stake, tipIsHomeTeam);

    if (matchDataDoc.hteam_odds && matchDataDoc.ateam_odds) {
      drawer.show();
    } else {
      addToast({
        message: 'match currently unavailable',
        type: 'error',
        dismissible: true,
        timeout: 2000,
      });
    }
  };

  async function confirmTip() {
    loading = true;
    error = false;
    if (tip === null || stake === null) {
      loading = false;
    } else {
      try {
        let response = await submitTips({
          tip: tip,
          stake: parseFloat(stake.toFixed(2)),
          matchId,
          tipIsHomeTeam,
          sport: '__sport__',
        });
        getCrowdTips(matchId);
        if (response.data.success) {
          addToast({
            message: 'tip submitted',
            type: 'success',
            dismissible: true,
            timeout: 2000,
          });
          tip = response.data.tip.tip;
          stake = response.data.tip.stake;
          tipIsHomeTeam = response.data.tip.homeTeam;
          buttonState = 'is-disabled';
        } else if (!response.data.success) {
          console.error('Tip failed to update');
          addToast({
            message: 'tip failed',
            type: 'error',
            dismissible: true,
            timeout: 4000,
          });
          errorMessage = response.data.message;
          error = true;
        } else {
          console.error('Error submitting tip.');
          addToast({
            message: 'tip failed',
            type: 'error',
            dismissible: true,
            timeout: 4000,
          });
        }
        loading = false;
      } catch (error) {
        console.error('Error submitting tip.', error);
        addToast({
          message: 'tip failed',
          type: 'error',
          dismissible: true,
          timeout: 4000,
        });
        loading = false;
      }
    }
  }
</script>

<sl-drawer
  placement="bottom"
  no-header
  label="Add/Edit Tip"
  class="drawer-custom-size drawer-placement-bottom"
  bind:this={drawer}
>
  <!-- For new bottom drawer I feel like users don't need a close button... -->
  <Flex justify="space-between" margin="5px 0px 5px 0px">
    <Text fontWeight="600" fontColor="#333">submit a tip</Text>
    <span role="button" tabindex="0" on:click={drawer.hide()}>
      <Icon name="cross" height="15px" />
    </span>
  </Flex>
  <div id="match-draw">
    <Flex direction="column">
      <VerticalSpacer height="5px" />
      <Flex>
        <ThickBorder
          borderColor={tipIsHomeTeam === true
            ? 'rgba(254,222,71,0.5)'
            : 'rgb(240,240,240)'}
          dropShadow={tipIsHomeTeam === true ? 'null' : 'md'}
        >
          <Flex alignItems="center" direction="column" margin="5px 0px 0px 0px">
            <Flex
              onClick={() => {
                if (pastStartTime) return;
                setButtonState(matchData.hometeam, stake, true);
              }}
              ><Box width="150px" height="120px">
                <TeamPanel
                  inactive={pastStartTime}
                  teamName={matchData.hometeam}
                  teamOdds={matchData.hteam_odds}
                  tipped={userTips.find(
                    (tip) => tip.matchId === matchData.doc_id
                  )}
                />
                {#if tipIsHomeTeam === true}
                  <div class="selected-icon">
                    <Icon
                      margin="0px 0px"
                      filter={pastStartTime
                        ? 'invert(55%) sepia(0%) saturate(1847%) hue-rotate(184deg) brightness(92%) contrast(100%)'
                        : ''}
                      height="15px"
                      name="up"
                    />
                  </div>{/if}
                {#if $crowdTips[matchId]}
                  <div
                    style="position: absolute; bottom: 0px; left: 0px; width: 100%; text-align:center;"
                  >
                    <Text fontWeight="300" fontSize="xx-small"
                      >{Math.round($crowdTips[matchId].hometeam)}% tipping {slangTeams[
                        matchData.hometeam
                      ].toLowerCase()}</Text
                    >
                  </div>
                {/if}</Box
              ></Flex
            ></Flex
          ></ThickBorder
        >
        <ThickBorder
          borderColor={tipIsHomeTeam === false
            ? 'rgba(254,222,71,0.5)'
            : 'rgb(240,240,240)'}
          dropShadow={tipIsHomeTeam === false ? 'null' : 'md'}
        >
          <Flex alignItems="center" direction="column" margin="5px 0px 0px 0px">
            <Flex
              onClick={() => {
                if (pastStartTime) return;
                setButtonState(matchData.awayteam, stake, false);
              }}
              ><Box width="150px" height="120px">
                <TeamPanel
                  inactive={pastStartTime}
                  teamName={matchData.awayteam}
                  teamOdds={matchData.ateam_odds}
                  tipped={userTips.find(
                    (tip) => tip.matchId === matchData.doc_id
                  )}
                />
                {#if tipIsHomeTeam === false}
                  <div class="selected-icon">
                    <Icon
                      margin="0px 0px"
                      filter={pastStartTime
                        ? 'invert(55%) sepia(0%) saturate(1847%) hue-rotate(184deg) brightness(92%) contrast(100%)'
                        : ''}
                      height="15px"
                      name="up"
                    />
                  </div>{/if}

                {#if $crowdTips[matchId]}
                  <div
                    style="position: absolute; bottom: 0px; right: 0px; width: 100%; text-align:center;"
                  >
                    <Text fontWeight="300" fontSize="xx-small"
                      >{Math.round($crowdTips[matchId].awayteam)}% tipping {slangTeams[
                        matchData.awayteam
                      ].toLowerCase()}</Text
                    >
                  </div>
                {/if}</Box
              ></Flex
            >
          </Flex>
        </ThickBorder>
      </Flex>
      <VerticalSpacer height="5px" />
      <Text fontSize="11px" fontWeight="300">*latest odds</Text>
    </Flex>
    <VerticalSpacer />

    <Flex justify="flex-start">
      <Flex direction="column">
        <Text fontSize="medium" fontWeight="300">stake</Text>
        <VerticalSpacer height="5px" />
        <div class="stake-input">
          <Text fontSize="large">$</Text>
          <input
            min="0"
            disabled={pastStartTime}
            class="ot-input"
            type="number"
            bind:value={stake}
            on:input={() => {
              if (stake < 0) stake = 0;
              setButtonState(tip, stake, tipIsHomeTeam);
            }}
          />
        </div>
      </Flex>
      <!-- <VerticalSpacer height="10px" /> -->
      <HorizontalSpacer width="40px" />
      <Flex direction="column" justify="flex-start">
        <Text fontSize="medium" fontWeight="300">return</Text>
        <VerticalSpacer height="10px" />
        <Text fontSize="large">${$potentialReturn.toFixed(2)}</Text>
      </Flex>
    </Flex>
    <VerticalSpacer height="16px" />
    <Text fontSize="medium" fontWeight="300">available funds</Text>
    <Text fontSize="large">${$userStore?.walletTotal.toFixed(2)}</Text>
    <VerticalSpacer height="5px" />
    <Flex direction="row" alignItems="center" justify="flex-start">
      <Button
        padding="5px 10px"
        disabled={buttonState === 'is-disabled' || pastStartTime}
        onClick={confirmTip}
        >{userTips.find((tip) => tip.matchId === matchData.doc_id)?.tip
          ? 'update'
          : 'submit'}
      </Button>
      <div style="margin-left: 10px; max-width: 180px;">
        <Text fontSize="11px" fontWeight="300"
          >*change your tip any time before kick-off</Text
        >
      </div>
    </Flex>
    <Flex margin="6px 0px">
      <Text fontColor="grey" fontSize="medium">
        {#if !tip}
          no team selected
        {/if}
      </Text>
    </Flex>

    {#if pastStartTime}
      <div id="past-start-message">
        <Message
          transparentBackground
          colour="#ff6700"
          icon="exclamation_yellow">match start time passed.</Message
        >
      </div>
    {/if}

    {#if error}
      <div class="mt-20 mb-20">
        <Message colour="#ff6700" icon="exclamation_yellow"
          >{errorMessage}</Message
        >
      </div>
    {/if}

    {#if userTips.find((tip) => tip.matchId === docId)}
      <Message
        icon={!pastStartTime
          ? 'clock'
          : isWinner === true
            ? 'coin_check'
            : isWinner === false
              ? 'coin_cross'
              : 'clock'}
        ><Flex><Text fontSize="small" fontWeight="300">your tip:</Text></Flex
        ><Flex direction="column"
          ><Text fontSize="medium"
            >{slangTeams[
              userTips.find((tip) => tip.matchId === matchData.doc_id).tip
            ]}</Text
          >
          <VerticalSpacer height="2px" />
          <Text fontSize="medium"
            >${userTips
              .find((tip) => tip.matchId === matchData.doc_id)
              .stake.toFixed(2)
              .replace(/[.,]00$/, '')} @ ${userTips
              .find((tip) => tip.matchId === matchData.doc_id)
              .odds.toFixed(2)} (${(
              userTips.find((tip) => tip.matchId === matchData.doc_id).stake *
              userTips.find((tip) => tip.matchId === matchData.doc_id).odds
            ).toFixed(2)})</Text
          ></Flex
        ></Message
      >
    {/if}
    {#if loading}
      <div class="loading-overlay">
        <Loading {loading} />
      </div>
    {/if}
  </div>
</sl-drawer>

<div
  role="button"
  tabindex="0"
  on:click={() => {
    if (!canTip) return;
    openDrawer(
      userTips.find((tip) => tip.matchId === matchData.doc_id),
      matchData
    );
  }}
>
  <!-- If the match starttime has passed, we flatten the border so it doesn't look clickable -->
  <ThickBorder borderColor={'grey'} margin="0px 0px 6px 0px" dropShadow="null">
    <!-- dropShadow={matchData.starttime < new Date().getTime() || !canTip
      ? null
      : 'md'} -->
    <MatchInformation
      {loading}
      {matchData}
      tipped={userTips.find((tip) => tip.matchId === matchData.doc_id)}
    />
  </ThickBorder>
</div>

<style>
  #past-start-message {
    position: absolute;
    top: 170px;
    width: 100%;
    text-align: center;
    /* transform: rotate(15deg); */
  }
  .selected-icon {
    position: absolute;
    top: 0px;
    left: 15px;
    transform: rotate(90deg);
  }

  sl-drawer::part(body) {
    padding: 0px 5px 20px 5px;
    background-color: #fff;
    width: 97%;
    max-width: 610px;
    border: black solid 2px;
    margin-top: 2px;
    border-image-slice: 3;
    border-image-width: 3;
    border-image-repeat: stretch;
    border-image-source: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="UTF-8" ?><svg version="1.1" width="8" height="8" xmlns="http://www.w3.org/2000/svg"><path d="M3 1 h1 v1 h-1 z M4 1 h1 v1 h-1 z M2 2 h1 v1 h-1 z M5 2 h1 v1 h-1 z M1 3 h1 v1 h-1 z M6 3 h1 v1 h-1 z M1 4 h1 v1 h-1 z M6 4 h1 v1 h-1 z M2 5 h1 v1 h-1 z M5 5 h1 v1 h-1 z M3 6 h1 v1 h-1 z M4 6 h1 v1 h-1 z" fill="rgb(221,221,221)" /></svg>');
    border-image-outset: 2;
  }

  sl-drawer::part(panel) {
    position: relative;
    width: 97%;
    max-width: 610px;
    margin-bottom: -10px;
    height: 520px;
    max-height: 75%;
    background-color: #ffffff00;
    display: flex;
    align-items: center;
    box-shadow: none;
  }

  sl-drawer::part(base) {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    align-content: flex-end;
  }

  .ot-input {
    width: 100px;
    margin-left: 5px;
    font-size: large;
    font-family: 'Source Code Pro', monospace;
    padding: 0px 0px;
  }

  .stake-input {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .loading-overlay {
    position: fixed;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.1);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
  }

  #match-draw {
    position: relative;
    max-width: 600px;
    padding: 3px 0px 0px 0px;
  }
</style>
