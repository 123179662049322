<script>
  import { onMount } from 'svelte';
  import { fade } from 'svelte/transition';
  import CreateAccount from '../components/CreateAccount.svelte';
  import SignIn from '../components/SignIn.svelte';
  import { Link } from 'svelte-routing';
  // import { competitionStatus } from '../stores/stores';
  import {
    Text,
    Button,
    Icon,
    Flex,
    VerticalSpacer,
    Divider,
    Heading,
  } from '../components/ui';
  import Quote from '../components/Quote.svelte';
  import TextBox from '../components/TextBox.svelte';
  import LogoLoading from '../components/LogoLoading.svelte';
  let loginDialog;
  let createAccountDialog;
  let joinLeagueDialog;
  // let url;
  const sport = '__sport__';
  const sportName = {
    afl: 'aussie rules',
    nrl: 'rugby league',
    test: 'testing',
  }[sport];
  $: isLeagueURL = false;
  $: isJoinLeagueURL = false;

  onMount(() => {
    // url = window.location.pathname;
    isLeagueURL = window.location.pathname.includes('leagues');
    // get query params
    const params = new URLSearchParams(window.location.search);
    isJoinLeagueURL = params.get('joinLeague');
    if (isLeagueURL || isJoinLeagueURL)
      // should have a better way to do this...
      setTimeout(() => {
        joinLeagueDialog.show();
      }, 1000);
  });
</script>

<div class="login-container" in:fade={{ delay: 250, duration: 500 }}>
  <Flex margin="0px 10px" direction="column" alignItems="center">
    <div
      style="position:relative; display: flex; flex-direction: column; align-items: flex-end;"
    >
      <div
        in:fade={{ delay: 250, duration: 1000 }}
        style="width: 85vw; max-width: 400px;"
      >
        <!-- replace with LogoLoading -->
        <LogoLoading repeat={false} />
        <div style="margin: 10px 0px 10px 0px;position:relative;top:-10px;">
          <Flex justify="flex-end">
            <Text fontSize="small" fontWeight="600">{sportName}</Text>
          </Flex>
        </div>
      </div>
    </div>

    <Flex
      alignItems="center"
      direction="column"
      width="250px"
      margin="0px 0px 50px 0px"
    >
      <Text fontSize="medium">It's not real money,</Text>
      <Text fontSize="medium">but it is real fun!</Text>
    </Flex>
    <Link to="/rules">
      <Flex margin="0px 0px 20px 0px">
        <Text fontSize="small">Bit more info on this thing</Text><Icon
          name="arrow"
          height="15px"
          margin="3px 3px"
        /></Flex
      >
    </Link>
    <Link to="/sports">
      <Flex>
        <Text fontSize="small">Other Odd Tipping sports</Text><Icon
          name="arrow"
          height="15px"
          margin="3px 3px"
        /></Flex
      >
    </Link>
  </Flex>

  <sl-dialog no-header on:load={() => {}} bind:this={joinLeagueDialog}>
    <Flex margin="10px 0px">
      <Text fontSize="16px" fontWeight="600">This link requires a login</Text>
      <Icon
        name="cross"
        height="15px"
        onClick={() => joinLeagueDialog.hide()}
      />
    </Flex>
    <Text fontSize="medium">
      It looks like you're trying to access a tipping comp's league page.
    </Text>
    <VerticalSpacer />
    <Text fontSize="medium">
      You'll need to login or create an account first, then we'll take you to
      the league where you can join in the fun.
    </Text>
    <VerticalSpacer height="20px" />
    <Flex>
      <Button
        fontSize="medium"
        height="45px"
        iconRight="login"
        primary
        onClick={async () => {
          loginDialog.show();
        }}
      >
        sign in
      </Button>
      <Button
        fontSize="medium"
        height="45px"
        onClick={() => createAccountDialog.show()}>create account</Button
      >
    </Flex>
    <!-- <Button primary onClick={() => joinLeagueDialog.hide()}
      >view sign in options</Button
    > -->
    <VerticalSpacer />
  </sl-dialog>
  <Flex margin="30px 0px" direction="column">
    <Flex direction="column">
      <Text fontSize="small">Got an account?</Text>
      <VerticalSpacer height="5px" />
      <Button
        fontSize="medium"
        height="45px"
        iconRight="login"
        primary
        onClick={async () => {
          loginDialog.show();
        }}
      >
        sign in
      </Button>
    </Flex>
    <VerticalSpacer height="20px" />
    <Flex direction="column">
      <Text fontSize="small">New? Get in here, legend.</Text>
      <VerticalSpacer height="5px" />
      <Button
        fontSize="medium"
        height="45px"
        onClick={() => createAccountDialog.show()}>create account</Button
      >
    </Flex>
  </Flex>

  <sl-dialog no-header bind:this={loginDialog}>
    <Flex margin="10px 0px">
      <Text fontSize="small">sign in</Text>
      <Icon name="cross" height="15px" onClick={() => loginDialog.hide()} />
    </Flex>
    <SignIn />
  </sl-dialog>

  <sl-dialog no-header bind:this={createAccountDialog}>
    <Flex margin="10px 0px">
      <Text fontSize="16px" fontWeight="600">create account</Text>
      <Icon
        name="cross"
        height="15px"
        onClick={() => createAccountDialog.hide()}
      />
    </Flex>
    <CreateAccount
      redirectLink={isJoinLeagueURL ? `/leagues/${isJoinLeagueURL}` : null}
    />
  </sl-dialog>
</div>

<div id="section-container">
  <Divider />
  <VerticalSpacer height="30px" />
  <Flex direction="column">
    <Heading fontSize="14px">How to play Odd Tipping</Heading>
    <Text fontSize="13px">(obviously after signing up and logging in)</Text>
    <VerticalSpacer height="30px" />
    <TextBox
      text="Get a weekly top-up of fake funds to tip on games based on real odds."
      emoji="1️⃣"
    />
    <VerticalSpacer height="20px" />
    <TextBox
      text="Build up a Wallet of Monopoly money as the season goes on (or just lose it all every week like a dumbass)."
      emoji="2️⃣"
    />
    <VerticalSpacer height="20px" />
    <TextBox
      text="Pull your f***ing hair out when you change your tip at the last second and miss out on that underdog when you were saying all week they were going to win!"
      emoji="3️⃣"
    />
    <Link to="/rules">
      <Flex justify="center" margin="30px 0px 0px 0px">
        <Text fontSize="small">Bit more info on this thing</Text><Icon
          name="arrow"
          height="15px"
          margin="3px 3px"
        /></Flex
      >
    </Link>
  </Flex>
  <!-- <VerticalSpacer height="50px" />
  <Flex justify="center">
    <Text>sign up 👍🏻</Text>
  </Flex> -->
  <VerticalSpacer height="70px" />
</div>

<div id="section-container">
  <Divider />
  <VerticalSpacer height="30px" />
  <Flex direction="column">
    <Heading fontSize="14px">What people have said about Odd Tipping</Heading>
    <Text fontSize="13px">(from my memory)</Text>
    <VerticalSpacer height="30px" />
    <Quote
      quote="Losing the fake money annoyed me so much it actually ruined footy games... I don’t want to play anymore."
      author="my neighbour"
    />
    <VerticalSpacer height="20px" />
    <Quote
      quote="He made it all by himself from scratch... You should join!"
      author="my wife"
    />
    <VerticalSpacer height="20px" />
    <Quote
      quote="Having something (that is technically nothing) on games was really fun."
      author="someone I don't actually know"
    />
    <VerticalSpacer height="20px" />
    <Quote
      quote="Who even scrolls this far down a page? Just sign up, ya weirdo."
      author="me"
    />
    <!-- <VerticalSpacer height="20px" />
    <Quote quotationMarks={false} quote="😠" author="grandma" /> -->
  </Flex>
  <VerticalSpacer height="70px" />
</div>

<style>
  sl-dialog::part(body) {
    font-size: 13px;
    padding: 10px 10px;
    border-radius: 0px;
    border: solid black 3px;
  }

  .login-container {
    opacity: 1;
    height: 80vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  #section-container {
    width: 100%;
    padding: 10px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
</style>
