<script>
  export let src = '';
  export let alt = 'default alt';
  export let gradient = true;
  export let margin = '';
  export let height = '200px';
</script>

<div class="image-container" style="--margin: {margin}; --height: {height}">
  <img class="main-image" style="--height: {height}" {alt} {src} />
  {#if gradient}
    <div class="image-gradient" />
  {/if}
</div>

<style>
  .main-image {
    width: 100%;
    height: var(--height);
    object-fit: cover;
    margin-bottom: 16px;
  }

  .image-container {
    position: relative;
    width: 100%;
    height: var(--height);
    overflow: hidden;
    margin: var(--margin);
  }

  .image-gradient {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 0, 0, 0.8) 100%
    );
  }
</style>
