import { db } from "../firebase";
import {
  collection,
  doc,
  query,
  orderBy,
  limit,
  getDocs,
  getDoc,
  startAfter,
} from "firebase/firestore";
import { blogPosts } from "../stores/stores";

export const getBlogPosts = async (lastDoc) => {
  let blogPostsQuery;
  if (!lastDoc) {
    blogPostsQuery = query(
      collection(db, "blog-posts", "__sport__", "articles"),
      orderBy("published", "desc"),
      limit(10)
    );
  } else {
    blogPostsQuery = query(
      collection(db, "blog-posts", "__sport__", "articles"),
      orderBy("published", "desc"),
      startAfter(lastDoc),
      limit(10)
    );
  }

  const blogPostsSnap = await getDocs(blogPostsQuery);
  const blogPostsData = blogPostsSnap.docs.map((doc) => {
    return { id: doc.id, ...doc.data() };
  });
  const newIds = blogPostsData.map((post) => post.id);
  blogPosts.update((curr) => [
    ...curr.filter((post) => !newIds.includes(post.id)),
    ...blogPostsData,
  ]);
  return blogPostsData[blogPostsData.length - 1]; // return the lastDoc for pagination
};

export const getBlogPostById = async (id) => {
  const blogPostRef = doc(db, "blog-posts", "__sport__", "articles", id);
  const blogPostSnap = await getDoc(blogPostRef);
  if (!blogPostSnap.exists()) return { content: "not found..." };
  const blogPost = blogPostSnap.data();
  return blogPost;
};
