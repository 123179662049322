<script>
  import { Flex, Text } from './ui';
  export let quote = '';
  export let author = '';
  export let quotationMarks = true;
</script>

<Flex direction="column" padding="15px" borderLeft="2px solid #FFDE46">
  <Text fontSize="15px" fontWeight="400"
    >{quotationMarks ? '"' : ''}{quote}{quotationMarks ? '"' : ''}</Text
  >
  <Text fontSize="13px" fontWeight="300">- {author}</Text>
</Flex>
