<script>
  import { Link } from 'svelte-routing';
  import { userStore } from '../stores/stores';
  export let headers;
  export let data;
  export let dataKeys;
  export let dataPrefixes;
</script>

<div style="overflow-x:auto;">
  <table>
    <thead>
      <tr>
        {#each headers as header}
          <th style={header === '#' ? 'width: 10%' : ''}>{header}</th>
          <!--  style="width: 10%" -->
        {/each}
      </tr>
    </thead>
    <tbody>
      {#each data as item}
        <tr class={item.id === $userStore.id ? 'user-position' : ''}>
          {#each dataKeys as key, i}
            <td>
              {#if key === 'name'}
                <Link to={`/user/${item.id}`}>{item[key]}</Link>
              {:else}
                {dataPrefixes[i] || ''}{item[key]}
              {/if}
            </td>
          {/each}
        </tr>
      {/each}
    </tbody>
  </table>
</div>

<style>
  table {
    width: 100%;
  }

  td {
    text-align: left;
    font-family: 'Source Code Pro', monospace;
    font-size: 13px;
    padding: 5px 5px;
    overflow: hidden;
    max-width: 12ch;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  th {
    text-align: left;
    font-size: 13px;
    font-family: 'Source Code Pro', monospace;
    padding: 5px 5px;
    font-weight: 300;
  }

  .user-position {
    background-color: #209cee72;
  }
</style>
