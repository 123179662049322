<script>
  import { onMount } from 'svelte';
  import Wallet from './Wallet.svelte';
  import { fade } from 'svelte/transition';
  import { userStore } from '../stores/stores';
  import { viewedUserStore, viewedUserId } from '../stores/stores';
  import { getViewedUserData } from '../utils/wallets';
  import { Text, Flex, LoadingText } from '../components/ui';
  export let id;
  let loading = true;
  let isUser = false;

  onMount(async () => {
    isUser = $userStore.id === id ? $userStore : await getViewedUserData(id);
    if (!isUser) {
      loading = false;
      return;
    }
    loading = false;
    viewedUserId.set(id);
  });
</script>

<Flex direction="column">
  {#if loading}
    <div class="loading-container">
      <LoadingText />
    </div>
  {:else if isUser}
    <div in:fade|global={{ delay: 100, duration: 500 }}>
      <Wallet user={$userStore.id === id ? $userStore : $viewedUserStore} />
    </div>
  {:else}
    <Text>Bah bow. No one here... Check your link is correct.</Text>
  {/if}
</Flex>

<style>
  .loading-container {
    height: 80vh;
    width: 100%;
  }
</style>
