<script>
  export let chartData = [];
  export let formatValFunc = (val) => val;
  export let liveValue = null;
  export let maxRangeY = Math.max(Math.max(...chartData) * 1.1, liveValue);
  export let indexes = [];
  export let currentRound = null;
  // Controls how to orient the y-axis:
  //   "desc" => 1 at top, maxRangeY at bottom
  //   "asc"  => 1 at bottom, maxRangeY at top

  export let direction = 'desc';
  const currentRoundIndex = indexes.indexOf(currentRound);
  if (currentRoundIndex !== -1) {
    chartData[currentRoundIndex] = liveValue;
  }

  let viewBoxWidth = 320;
  let viewBoxHeight = 70;
  let paddingLeft = 6; // Increased padding for y-axis labels
  let paddingBottom = 0;
  let paddingTop = 3;
  let numberOfPoints = chartData.length;

  // Gap determines the spacing between points along the x-axis
  let gap = (viewBoxWidth - paddingLeft * 2) / (numberOfPoints - 1);

  // this parsing is a mess...
  // isBlank means the round happened before the user signed up
  // isNull means the round hasn't happened yet

  // Recompute `data` reactively whenever `gap` or `yValues` changes:
  // - 1 at top => ratio is (value - 1) / (maxRangeY - 1), so value=1 -> ratio=0 (top)
  //   and value=maxRangeY -> ratio=1 (bottom).
  $: data = chartData.map((value, index) => {
    const isBlank = value === -1;
    if (isBlank) {
      if (direction === 'asc') {
        value = 1;
      } else {
        value = maxRangeY;
      }
    }
    const isNull = value === null;
    const x = paddingLeft + index * gap;
    const previousValue =
      index === 0
        ? value
        : chartData[index - 1] === null
          ? chartData.findLast((v) => v !== null)
          : chartData[index - 1];
    if (isNull) {
      value = previousValue || maxRangeY;
    }

    // Compute Y position depending on direction
    const chartHeight = viewBoxHeight - paddingTop - paddingBottom;
    const ratio = (value - 1) / (maxRangeY - 1);

    let y;
    if (direction === 'desc') {
      // 1 => top, maxRangeY => bottom
      y = ratio * chartHeight;
    } else {
      // 'asc': 1 => bottom, maxRangeY => top
      y = (1 - ratio) * chartHeight;
    }

    return {
      x: x,
      y: y,
      textSmall: `round ${indexes[index]}`,
      value: `${formatValFunc(value)}`,
      isNull,
      isBlank,
    };
  });

  let activeIndex = currentRoundIndex || null;

  function toggleTooltip(index) {
    // activeIndex = activeIndex === index ? null : index;
    activeIndex = index;
  }

  function handleKeydown(event, index) {
    if (event.key === 'Enter' || event.key === ' ') {
      toggleTooltip(index);
      event.preventDefault(); // Prevent scrolling on space press
    }
  }
</script>

<svg
  class="responsive-svg"
  viewBox="0 0 {viewBoxWidth} {viewBoxHeight}"
  preserveAspectRatio="xMidYMid meet"
>
  <!-- Y Axis -->
  <line
    x1={paddingLeft}
    y1={paddingTop}
    x2={paddingLeft}
    y2={viewBoxHeight - paddingBottom}
    stroke="#d9d9d9"
  />

  <!-- X Axis -->
  <line
    x1={paddingLeft}
    y1={viewBoxHeight - paddingBottom}
    x2={viewBoxWidth - paddingLeft}
    y2={viewBoxHeight - paddingBottom}
    stroke="#d9d9d9"
  />

  <!-- Lines between consecutive points -->
  {#each data as point, i}
    {#if i < data.length - 1}
      <line
        x1={point.x}
        y1={paddingTop + point.y}
        x2={data[i + 1].x}
        y2={paddingTop + data[i + 1].y}
        stroke={data[i + 1].isNull ? '#99999950' : '#fede4750'}
        stroke-width={data[i + 1].isNull ? 1 : 2}
        stroke-dasharray={data[i + 1].isNull ? '4,4' : '0'}
      />
    {/if}
  {/each}

  <!-- Points and tooltips with accessibility enhancements -->
  {#each data as point, index}
    {#if !(point.isBlank || point.isNull)}
      <circle
        cx={point.x}
        cy={paddingTop + point.y}
        r="3"
        fill={point.isNull || point.isBlank ? '#99999900' : '#fede47'}
        role="button"
        tabindex="0"
        aria-label={`Value ${point.value} at position ${index + 1}`}
        on:click={() => toggleTooltip(index)}
        on:keydown={(event) => handleKeydown(event, index)}
        class={index === currentRoundIndex ? 'focusable pulse' : 'focusable'}
      />
      {#if activeIndex === index}
        <text
          class="text-small"
          x={point.x + 7}
          y={paddingTop + point.y - 3}
          text-anchor="right"
          fill="#999"
          font-size="10px"
        >
          {point.textSmall}
        </text>
        <text
          class="text-large"
          x={point.x + 7}
          y={paddingTop + point.y + 5}
          text-anchor="right"
          fill="#333"
        >
          {point.value}
        </text>
      {/if}
    {/if}
  {/each}
</svg>

<style>
  .responsive-svg {
    width: 100%;
    height: auto;
    max-width: 100%;
    overflow: visible;
    /* margin-left: -6px; */
  }

  text {
    font-family: 'Source Code Pro', monospace;
    font-style: normal;
  }

  .text-small {
    font-size: 7px;
  }

  .text-large {
    font-size: 9px;
    font-weight: 600;
  }

  .focusable:focus {
    outline: 1px solid #fede47; /* Yellow outline */
    outline-offset: 2px; /* Slight offset from the element */
    border-radius: 10px;
  }

  /* Not sure if I link this constant pulse or not... */
  /* @keyframes pulse {
    0% {
      filter: brightness(1);
    }
    50% {
      filter: brightness(0.9);
    }
    100% {
      filter: brightness(1);
    }
  }

  .pulse {
    animation: pulse 2s infinite;
  } */
</style>
