<script>
  import {
    Text,
    Flex,
    Icon,
    VerticalSpacer,
    ThickBorder,
    Heading,
    PageContainer,
  } from '../components/ui';
  import { Link } from 'svelte-routing';
  import { userStore } from '../stores/stores';
  import CreateLeague from '../components/CreateLeague.svelte';
  import JoinLeague from '../components/JoinLeague.svelte';
  import { onDestroy } from 'svelte';
  import createUserLeaguesStore from '../stores/leaguesStore.js';
  let user;
  let leagues;
  let infoDialog;

  userStore.subscribe((value) => (user = value));

  const userLeaguesStore = createUserLeaguesStore(user.id);
  userLeaguesStore.subscribe((value) => {
    leagues = value;
  });

  onDestroy(() => {
    userLeaguesStore.unsubscribe();
  });
</script>

<PageContainer>
  <VerticalSpacer height="20px" />
  <Flex justify="space-between">
    <Flex justify="flex-start">
      <Icon margin="0px 10px 0px 0px" name="users" />
      <Heading>leagues</Heading>
    </Flex>
    <div
      role="button" tabindex="0"
      on:click={() => infoDialog.show()}
      style="position:relative; display:inline-block; top: 3px; right: 3px; padding: 3px 3px; display: flex;"
    >
      <Icon margin="3px 5px 0px 0px" height="18px" name="info" />
    </div>
  </Flex>

  <VerticalSpacer />

  {#if leagues.length > 0}
    <VerticalSpacer height="5px" />
    {#each leagues as league}
      <Link to={`/leagues/${league.id}`}>
        <ThickBorder borderColor="grey" dropShadow="null">
          <Flex alignItems="center">
            <Flex padding="0px 0px 0px 10px" direction="column">
              <Text fontSize="large" fontWeight="600">{league.name}</Text>
              <Text fontWeight="300">{league.users.length} members</Text>
            </Flex>
            <Icon height="20px" margin="0px 5px" name="arrow" />
          </Flex>
        </ThickBorder>
      </Link>
      <VerticalSpacer height="15px" />
    {/each}
  {/if}
  {#if leagues.length === 0}<p>
      <Text fontSize="medium" fontColor="#999999"
        >Your joined and created leagues appear here.</Text
      >
    </p>
  {/if}
  <sl-dialog no-header bind:this={infoDialog}>
    <Flex margin="10px 0px">
      <Text fontSize="16px" fontWeight="600">Leagues explained</Text>
      <Icon name="cross" height="15px" onClick={() => infoDialog.hide()} />
    </Flex>
    <Flex direction="column">
      <Text fontSize="medium"
        >Leagues allow you to compete against a chosen group of mates. You can
        join or create multiple leagues, if you actually have that many mates.</Text
      >
      <VerticalSpacer />
      <Text fontSize="medium">
        Your tips and wallet are the same across all leagues you join.
      </Text>
      <Text fontSize="medium">
        You can create, join or leave leagues at any point during a season.</Text
      >
      <VerticalSpacer />
      <Text fontSize="medium"
        >To add someone to this league, copy a league code or link from the
        'create/join' section on the leagues page and send it to them. To join,
        they'll need to input the join code or select 'join league' after
        visiting the link.</Text
      >
    </Flex>
  </sl-dialog>
  <VerticalSpacer />
  <ThickBorder borderColor="grey" dropShadow="null" margin="6px 0px">
    <sl-tab-group>
      <sl-tab slot="nav" panel="join">join</sl-tab>
      <sl-tab slot="nav" panel="create">create</sl-tab>
      <sl-tab-panel name="join">
        <JoinLeague />
      </sl-tab-panel>
      <sl-tab-panel name="create">
        <CreateLeague {user} />
      </sl-tab-panel>
    </sl-tab-group>
  </ThickBorder>
</PageContainer>

<style>
</style>
