<script>
  import { Flex, Text } from './ui';
  export let text = '';
  export let emoji = '';
</script>

<div class="text-border">
  <div class="text">
    <Flex alignItems="center" justify="flex-start">
      <Text fontSize="24px" margin="0px 20px 0px 0px">{emoji}</Text>
      <Text fontSize="15px">{text}</Text>
    </Flex>
  </div>
</div>

<style>
  .text-border {
    position: relative;
    top: 5px;
    left: 5px;
    background-color: #fede47;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .text {
    width: 100%;
    padding: 15px;
    position: relative;
    background-color: #fff;
    top: -10px;
    left: -10px;
    border: #999 solid 1px;
  }
</style>
