import { db } from '../firebase/';
import {
  fixtures,
  currentRound,
  competitionStatus,
  rounds,
  userCount,
} from '../stores/stores.js';
import { get } from 'svelte/store';
import {
  collection,
  query,
  where,
  orderBy,
  getDocs,
  getDoc,
  doc,
} from 'firebase/firestore';

// gets the round of the next upcoming game
export const getCurrentRound = async () => {
  if (!get(currentRound)) {
    // compute the current round to display
    const fixtureMetaSnap = await getDoc(
      doc(db, '__meta_collection__', 'season')
    );
    const fixtureMeta = fixtureMetaSnap.data();
    const round = fixtureMeta.currentRound;
    const status = fixtureMeta.status;
    const seasonRounds = fixtureMeta.rounds;
    const seasonUserCount = fixtureMeta.userCount;
    competitionStatus.set(status);
    currentRound.set(round);
    rounds.set(seasonRounds);
    userCount.set(seasonUserCount);
    return round;
  } else {
    return get(currentRound);
  }
};

// gets the fixtures for the given round
const getFixtures = async (round) => {
  let matchData = {}; // ...get(fixtures) - we're moving to fetching every time becasue the damn data doesn't update properly in the UI for some reason when it is all there. Object shit
  // if (!matchData[round]) {
  const q = query(
    collection(db, '__fixture_collection__'),
    where('round', '==', round),
    orderBy('starttime')
  );

  const snapshot = await getDocs(q);
  const matchesData = snapshot.docs.map((doc) => ({
    doc_id: doc.id,
    ...doc.data(),
  }));
  matchData[round] = [...matchesData];
  fixtures.set({ ...matchData });
  // }
};

export { getFixtures };
