<script>
  import {
    Text,
    Heading,
    VerticalSpacer,
    PageContainer,
    Image,
  } from '../components/ui';
</script>

<PageContainer>
  <VerticalSpacer height="20px" />
  <!-- <Icon name="help" margin="0px 10px 0px 0px" /> -->
  <Heading fontSize="14px">How to play Odd Tipping</Heading>
  <p>
    Odd Tipping is a footy tipping game with a twist... it's actually fun! 🎉
  </p>
  <p>1️⃣ You stake Monopoly money on footy games based on real odds.</p>
  <p>
    2️⃣ A winning tip gets you the value of the tip x the odds added to your Wallet of
    fake funds.
  </p>
  <p>
    3️⃣ Every round, all players receive $100 fake dollars to spend on the
    current round, so you can keep up hope of a comeback even when you lose it
    all every week like a dumbass.
  </p>
  <p>
    4️⃣ The person with the most fake money at the end of the season is the
    winner.
  </p>
  <p>
    Because of the obvious connection to gambling, Odd Tipping has a recommended
    age rating of 18+ ...But it's just fake money. By these standards Monopoly
    should be “adults only”.
  </p>
  <VerticalSpacer height="40px" />
  <Heading fontSize="14px">Get to know your wallet</Heading>
  <VerticalSpacer height="20px" />
  <Image
    height="100%"
    gradient={false}
    src={'https://storage.googleapis.com/odd-tipping.appspot.com/assets/wallet-with-notes.png'}
  />
  <VerticalSpacer height="40px" />
  <Heading fontSize="14px">Rules and stuff</Heading>
  <Text fontSize="14px"
    >(Honestly read this, please... you wouldn't believe how wrong people get
    it)</Text
  >
  <p>
    ➡️ Every round, every user is issued 100 tipping credits (fake dollars) to
    use in addition to credits already in their wallet.
  </p>
  <p>
    ➡️ The fake funds you receive each round are "use it or lose it", meaning if
    you only use $80 of the $100, at the end of the round, $20 will be taken
    back from your wallet. This is to ensure "ghost" players don't win.
  </p>
  <p>➡️ Clearly, the fake funds issued in this game are not real money.</p>
  <p>
    ➡️ Games are open for tipping if they have odds and the start time has not
    passed (tips can be edited and submitted up until each game's start time).
  </p>
  <p>
    ➡️ Unlike traditional tipping, there is no incentive to tip a game you have
    no confidence in tipping the winner.
  </p>
  <p>
    ➡️ To place a tip you must select a team, input a valid amount (your
    'stake') that is less than the current funds of your Wallet and
    'submit/update' the tip.
  </p>
  <p>
    ➡️ Your Wallet displays how much Monopoly money you have available for
    placing tips as well as any pending or settled tips for your account.
  </p>
  <p>
    ➡️ If you have insufficient funds in your Wallet to place a tip, you will
    not be able to submit the tip.
  </p>
  <p>
    ➡️ If you have placed a tip and the game has not yet started, you can edit
    the tip by selecting the game and updating the tip.
  </p>
  <p>
    ➡️ You can do stuff like win funds on the first game of the round and then
    re-invest it on the next game and I'm no Wall Street guru but the more you
    bet, the more you win, right?
  </p>
  <p>
    ➡️ For each successful tip, your wallet will be credited the total of your
    'stake' for that match, multiplied by the odds of the tipped team as of the
    time of submitting your tip.
  </p>
  <p>
    ➡️ Successful tips are aimed to be credited to your wallet within 2 hours of
    the game's completion, but jeez... it's just one guy running this, give me a
    break. You'll get it!
  </p>
  <p>
    ➡️ Just to reiterate... any won funds can be used immediately for any games
    currently open for tipping.
  </p>
  <p>
    ➡️ Odds are based on the average odds provided by a selection of bookmakers,
    not one in particular.
  </p>
  <p>
    ➡️ Odds change in the lead-up to a match, but you get the odds at the point
    you submit the tip.
  </p>
  <p>
    ➡️ Currently, there is no prize for winning Odd Tipping (other than pride,
    of course!).
  </p>
  <VerticalSpacer height="70px" />
</PageContainer>

<style>
  p {
    font-family: 'Source Code Pro', monospace;
    /* font-family: 'Montserrat', sans-serif; */
    font-size: 16px;
    padding-bottom: 10px;
    padding-top: 10px;
  }
</style>
