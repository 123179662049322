import { db } from '../firebase/';
import {
  documentId,
  collection,
  query,
  orderBy,
  startAt,
  endAt,
  getDocs,
} from 'firebase/firestore';
import { overallLeaderboard } from '../stores/stores';

export const getLeaderboard = async (round) => {
  const padStartRound = round.toString().padStart(2, 0);
  const roundKey = round === 'overall' ? 'overall' : `round_${padStartRound}`;

  const leaderboardsRef = collection(
    db,
    '__meta_collection__',
    'season',
    'leaderboards'
  );
  // Query for all docs/shards with IDs that start with "round_"
  const q = query(
    leaderboardsRef,
    orderBy(documentId()),
    startAt(`${roundKey}`),
    endAt(`${roundKey}\uf8ff`) // \uf8ff is a high codepoint that effectively means "round_ anything"
  );

  const querySnap = await getDocs(q);

  if (querySnap.empty) {
    return [];
  }

  const comboLeaderboardSnap = querySnap.docs.reduce((prev, curr) => {
    return { ...prev, ...curr.data() };
  }, {});

  let leaderboardData = Object.entries(comboLeaderboardSnap)
    .map(([key, value]) => {
      // this is a unique case for the 'overall' leaderboard - round leadeboard data has pending, but not walletTotal
      if (Object.prototype.hasOwnProperty.call(value, 'wT')) {
        return {
          id: key,
          name: value.n,
          walletTotal: value.wT,
          netWorth: value.nW.toFixed(2).replace(/[.,]00$/, ''),
          form: value.f.reduce((acc, curr) => acc + `${curr} `, ''),
          pending: (value.nW - value.wT).toFixed(2).replace(/[.,]00$/, ''),
        };
      }
      // for individual round leaderboards, we won't have a netWorth in the data until after the first match is finalised
      if (!value.nW) value.nW = 0;
      if (Object.prototype.hasOwnProperty.call(value, 'tB')) {
        value.nW = value.nW - value.tB;
      }
      return {
        id: key,
        name: value.n,
        netWorth: value.nW.toFixed(2).replace(/[.,]00$/, ''),
        pending: Object.prototype.hasOwnProperty.call(value, 'p')
          ? value.p.toFixed(2).replace(/[.,]00$/, '')
          : 0,
        takeBack: Object.prototype.hasOwnProperty.call(value, 'tB')
          ? value.tB.toFixed(2).replace(/[.,]00$/, '')
          : null,
      };
    })
    .sort((a, b) => {
      // Compare by 'networth' first
      if (a.netWorth !== b.netWorth) {
        return b.netWorth - a.netWorth;
      }

      // If 'networth' is equal, compare by 'name'
      return a.name.localeCompare(b.name);
    })
    .map((item, i) => ({ ...item, rank: i + 1 }));
  if (round === 'overall') overallLeaderboard.set(leaderboardData);
  return leaderboardData;
};

// export const getLeaderboardByRound = async (round, lastVisible) => {
//   // const q = query(
//   //   collection(db, "__users_collection__"),
//   //   where(`roundsSummary.${round}`, "!=", null),
//   //   orderBy(`roundsSummary.${round}.netWorth`, "desc"),
//   //   limit(20)
//   // );
//   let q;
//   if (!lastVisible) {
//     q = query(
//       collection(db, "__users_collection__"),
//       where(`roundsSummary.${round}.netWorth`, "!=", null),
//       orderBy(`roundsSummary.${round}.netWorth`, "desc"),
//       limit(20)
//     );
//   } else {
//     q = query(
//       collection(db, "__users_collection__"),
//       where(`roundsSummary.${round}.netWorth`, "!=", null),
//       orderBy(`roundsSummary.${round}.netWorth`, "desc"),
//       startAfter(lastVisible),
//       limit(20)
//     );
//   }
//   const leaderboardData = await getDocs(q);
//   return leaderboardData;
// }
