<script>
  import { getBlogPosts } from "../utils/blog-posts";
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";
  import {
    Heading,
    VerticalSpacer,
    Flex,
    PageContainer,
  } from "../components/ui";
  import BlogCard from "../components/BlogCard.svelte";
  import { blogPosts } from "../stores/stores";
  let blogLastDoc = null;
  // TODO - implement 'load more' button using blogLastDoc

  onMount(async () => {
    blogLastDoc = await getBlogPosts();
  });
</script>

<PageContainer>
  <VerticalSpacer height="20px" />
  <Flex justify="flex-start">
    <Heading>__sport__ articles</Heading>
  </Flex>
  <VerticalSpacer />
  {#each $blogPosts as post}
    {#if post.published}
      <div in:fade|global={{ delay: 50, duration: 250 }}>
        <BlogCard
          image={post.image}
          link={`/blog/${post.id}`}
          title={post.title}
          published={post.published}
          type={post.type}
        />
        <VerticalSpacer height="20px" />
      </div>
    {/if}
  {/each}
</PageContainer>

<style>
</style>
