<script>
  import DataTable from '../components/DataTable.svelte';
  import { getLeaderboard } from '../utils/leaderboards';
  import { userStore } from '../stores/stores';
  let leaderboardData;
  let loading = true;
  import { onMount } from 'svelte';
  import {
    Text,
    Flex,
    Icon,
    Heading,
    LoadingText,
    VerticalSpacer,
    PageContainer,
  } from '../components/ui';
  import RoundSelector from '../components/RoundSelector.svelte';

  const urlParams = new URLSearchParams(window.location.search);
  const round = urlParams.get('round');
  let selectedRound = parseInt(round) || 'overall';

  $: userRank = leaderboardData
    ? leaderboardData.findIndex((i) => i.id === $userStore.id) + 1
    : null;

  onMount(async () => {
    leaderboardData = await getLeaderboard(selectedRound);
  });
</script>

<PageContainer>
  <VerticalSpacer height="20px" />
  <Flex justify="flex-start">
    <Icon margin="0px 10px 0px 0px" name="trophy" />
    <Heading>leaderboard</Heading>
  </Flex>
  <RoundSelector
    extraRounds={['overall']}
    {selectedRound}
    onRoundSelect={async (round) => {
      loading = true;
      selectedRound = round;
      window.history.replaceState({}, '', '?' + urlParams.toString());
      const data = await getLeaderboard(round);
      leaderboardData = data;
      loading = false;
    }}
  />
  <VerticalSpacer />
  {#if leaderboardData}
    <Text fontSize={'13px'}
      >current ranking: <span style="font-weight: 600;"
        >{userRank} of {leaderboardData.length}</span
      ></Text
    >
    <VerticalSpacer />
    {#if selectedRound !== 'overall'}
      <DataTable
        data={leaderboardData}
        headers={['#', 'name', `round ${selectedRound}`, 'pending']}
        dataKeys={['rank', 'name', 'netWorth', 'pending']}
        dataPrefixes={[null, null, '$', '$']}
      />
    {:else}
      <DataTable
        data={leaderboardData}
        headers={['#', 'name', 'net worth', 'pending', 'form']}
        dataKeys={['rank', 'name', 'netWorth', 'pending', 'form']}
        dataPrefixes={[null, null, '$', '$']}
      />
    {/if}
  {:else if loading}
    <div class="loading-container">
      <LoadingText />
    </div>
  {:else}
    <Text>Something went wrong</Text>
  {/if}
  <VerticalSpacer height="10px" />
</PageContainer>

<style>
  .loading-container {
    height: 80vh;
    width: 100%;
  }
</style>
