<script>
  export let colour = 'green'; // this seems wrong here?
  const backgroundColour =
    {
      neutral: '#212529',
      primary: '#209cee', // #007AFF
      success: '#4BB543',
      warning: '#e76e55',
      light: '#999999',
    }[colour] ?? 'gray';
</script>

<div style="position: relative;">
  <sl-badge style="--colour:{backgroundColour};"><slot /></sl-badge>
</div>

<style>
  sl-badge::part(base) {
    font-family: 'Source Code Pro', monospace;
    border-radius: 0px;
    background-color: white;
    border-color: var(--colour);
    color: var(--colour);
    font-weight: 600;
    border: none;
    height: 24px !important;
    padding: 0px 0px !important;
  }
</style>
