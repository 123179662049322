import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from 'firebase/auth';

export const createNewUser = (email, password) => {
  const auth = getAuth();
  return new Promise((resolve, reject) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        resolve(user);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(
          'error in createUserWithEmailAndPassword',
          errorCode,
          errorMessage
        );
        reject(error);
      });
  });
};

export const signInUser = (email, password) => {
  const auth = getAuth();
  return new Promise((resolve, reject) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        resolve(user);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(
          'error in signInWithEmailAndPassword',
          errorCode,
          errorMessage
        );
        reject(error);
      });
  });
};

export const sendPasswordReset = (email) => {
  const auth = getAuth();
  return new Promise((resolve, reject) => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log('error in sendPasswordResetEmail', errorCode, errorMessage);
        reject(errorMessage);
      });
  });
};
