<script>
  import { fade } from 'svelte/transition';
  import Nav from './components/Nav.svelte';
  import Toasts from './components/alerts/Toasts.svelte';
  import Tipping from './pages/Tipping.svelte';
  import LogoLoading from './components/LogoLoading.svelte';
  import UserWallet from './pages/UserWallet.svelte';
  import Login from './pages/Login.svelte';
  import Rules from './pages/Rules.svelte';
  import AllSports from './pages/AllSports.svelte';
  import Leaderboard from './pages/Leaderboard.svelte';
  import Leagues from './pages/Leagues.svelte';
  import CreateLeague from './components/CreateLeague.svelte';
  import SingleLeague from './pages/SingleLeague.svelte';
  import UILibrary from './pages/UILibrary.svelte';
  import UserSettings from './pages/UserSettings.svelte';
  import BlogPost from './pages/BlogPost.svelte';
  import BlogList from './pages/BlogList.svelte';
  import WalletHistory from './pages/WalletHistory.svelte';
  import TipsHistory from './pages/TipsHistory.svelte';
  import NothingHere from './pages/NothingHere.svelte';
  import Tracking from './components/Tracking.svelte';
  import BackButton from './components/BackButton.svelte';
  import BrokenStatus from './pages/BrokenStatus.svelte';
  import {
    loadingUser,
    userStore,
    competitionStatus,
    currentRound,
    rounds,
  } from './stores/stores';
  import { createUserStore } from './stores/userStore.js';
  import { Router, Route } from 'svelte-routing';
  import Footer from './components/Footer.svelte';
  import Privacy from './pages/Privacy.svelte';
  import { onDestroy, onMount } from 'svelte';
  import { getCurrentRound } from './utils/fixtures';

  let user;
  $: loaded =
    typeof $currentRound === 'number' &&
    $competitionStatus &&
    $rounds &&
    !$loadingUser;
  let userStoreUnsubscribe;

  let sport = '__sport__';
  const sportName = {
    afl: 'Aussie Rules',
    nrl: 'Rugby League',
    test: 'testing',
  }[sport];

  // Could get more dynamic with the pageName
  // import { pageName } from "./stores.js";
  // $: title = $pageName ? `Odd Tipping — __sport__ – ${$pageName}` : 'Odd Tipping';
  $: title = `Odd Tipping - ${sportName}`;

  // Subscribe to userStore
  userStore.subscribe((value) => {
    if (value && value.id) {
      // here we're creating a listener on the db for real-time updates (eg. wallet balance)
      // only create listener if there is no unsubscribe function already
      if (!userStoreUnsubscribe) {
        userStoreUnsubscribe = createUserStore(value.id);
      }
    }
    user = value;
  });

  onMount(() => {
    // getCurrentRound triggers the meta collection data (competitionStatus, currentRound, etc.)
    try {
      getCurrentRound();
    } catch (error) {
      console.log('Error getting current round', error);
    }
  });

  onDestroy(() => {
    if (userStoreUnsubscribe) {
      userStoreUnsubscribe();
    }
  });
</script>

<Toasts />
<Router>
  <Tracking />
  {#if loaded}
    <!-- 
      Render Nav differently depending on whether user is logged in.
      This is unchanged from your original approach, but note that
      the route for "/" is now unified below.
    -->
    {#if $userStore}
      <Nav />
    {:else}
      <Nav loggedIn={false} />
    {/if}

    {#if $competitionStatus === 'broken'}
      <BrokenStatus />
    {:else}
      <main>
        <div
          class="app-container"
          transition:fade={{ delay: 350, duration: 500 }}
        >
          <BackButton />

          <!-- 
          1) Unified Route for "/"
             We now have one single <Route path="/"> block.
             Inside it, we conditionally show <Tipping /> or <Login />
             based on $userStore.
             
             This eliminates the old duplication of routes for "/"
             in separate if/else blocks and prevents leftover DOM issues.
        -->
          <Route path="/">
            {#if $userStore}
              <Tipping />
            {:else}
              <Login />
            {/if}
          </Route>

          <!-- 
          2) Protected (logged-in) routes
             We only want these accessible if $userStore exists, 
             so we wrap them in an #if $userStore block. 
             If the user tries to navigate to these routes
             while not logged in, there's no matching route 
             (and they'd fall back to <NothingHere /> or your default).
        -->
          {#if $userStore}
            <Route path="wallet/:id" component={UserWallet} />
            <Route path="wallet/:id/history" component={WalletHistory} />
            <Route path="wallet/:id/tips" component={TipsHistory} />
            <Route path="leaderboard" component={Leaderboard} />
            <Route primary={false} path="user/:id" component={UserWallet} />
            <Route path="leagues" component={Leagues} />
            <Route path="leagues/:id" component={SingleLeague} />
            <Route {user} path="leagues/create" component={CreateLeague} />
            <Route path="settings">
              <UserSettings />
            </Route>
          {/if}

          <!-- 
          3) Public routes
             These routes remain accessible to everyone (logged in or not).
        -->
          <Route path="blogs">
            <BlogList />
          </Route>
          <Route path="blog/:id" component={BlogPost} />
          <Route path="blog/:id" component={BlogPost} />

          <Route path="blog/:id" component={BlogPost} />

          <Route path="rules" component={Rules} />
          <Route path="rules" component={Rules} />

          <Route path="rules" component={Rules} />

          <Route path="privacy" component={Privacy} />
          <Route path="privacy" component={Privacy} />

          <Route path="privacy" component={Privacy} />

          <Route path="sports" component={AllSports} />
          <Route path="ui-library">
            <UILibrary />
          </Route>

          <!-- 
          4) Catch-all
             If none of the above match, show <NothingHere />.
             This is your 404-ish route.
        -->
          <Route path="*">
            <NothingHere />
          </Route>
        </div>
      </main>
      <Footer />
    {/if}
  {:else}
    <!-- 
      If we haven't loaded store data yet (still fetching),
      show a full-page LogoLoading spinner, same as your original code.
    -->
    <div class="full-page">
      <LogoLoading />
    </div>
  {/if}
</Router>

<svelte:head>
  <title>{title}</title>
  <!-- TODO - add a dynamic logo, icons etc. -->
</svelte:head>

<style>
  .app-container {
    margin-top: 65px;
    height: 100%;
  }
  .full-page {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
