import { writable } from 'svelte/store';
import { db } from '../firebase';
import { collection, query, onSnapshot, where } from 'firebase/firestore';

const { subscribe, set } = writable([]); // update also available from writeable
// TODO - confirm if this subscribe is actually working as expected in Tipping.svelte
let current;

export const getTipsByMatchIDs = (userId, matchIds) => {
  subscribe((value) => {
    current = value;
  });

  const tipsQuery = query(
    collection(db, '__users_collection__', userId, 'tips'),
    where('matchId', 'in', matchIds)
  );

  // we should be able to use this function for any user, but
  // may be a good idea to pass along their userID to filter on
  const unsubscribe = onSnapshot(tipsQuery, (querySnapshot) => {
    const userTips = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    set(userTips);
  });

  return unsubscribe;
};

export { subscribe };
