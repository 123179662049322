export function formatRank(rank) {
  if (!rank) return rank;
  let j = rank % 10,
    k = rank % 100;
  if (j == 1 && k != 11) {
    return rank + 'st';
  }
  if (j == 2 && k != 12) {
    return rank + 'nd';
  }
  if (j == 3 && k != 13) {
    return rank + 'rd';
  }
  return rank + 'th';
}

function escapeCSS(str) {
  return str.replace(/[!\s"#$%&'()*+,\-./:;<=>?@[\]\\^{|}~]/g, function (c) {
    return '\\' + c.charCodeAt(0).toString(16) + ' ';
  });
}

export function getEscapedSVG(borderColor) {
  var escapedSVG = escapeCSS(
    `data:image/svg+xml;utf8,<?xml version="1.0" encoding="UTF-8" ?><svg version="1.1" width="8" height="8" xmlns="http://www.w3.org/2000/svg"><path d="M3 1 h1 v1 h-1 z M4 1 h1 v1 h-1 z M2 2 h1 v1 h-1 z M5 2 h1 v1 h-1 z M1 3 h1 v1 h-1 z M6 3 h1 v1 h-1 z M1 4 h1 v1 h-1 z M6 4 h1 v1 h-1 z M2 5 h1 v1 h-1 z M5 5 h1 v1 h-1 z M3 6 h1 v1 h-1 z M4 6 h1 v1 h-1 z" fill="${borderColor}" /></svg>`
  );
  return escapedSVG;
}
