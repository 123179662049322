<script>
  import Flex from './Flex.svelte';
  import Button from './Button.svelte';
  import VerticalSpacer from './VerticalSpacer.svelte';
  import { signInUser, sendPasswordReset } from '../utils/password-login';
  import Loading from './Loading.svelte';
  import { addToast, userSigningIn } from '../stores/stores';
  let checked = false;
  let email;
  let password;
  let loading = false;
  const validate = async () => {
    loading = true;
    if (checked) {
      try {
        await sendPasswordReset(email);
        addToast({
          message: 'please check your email',
          type: 'success',
          dismissible: true,
          timeout: 5000,
        });
      } catch (error) {
        console.error('sendPasswordReset error', error);
        loading = false;
        addToast({
          message: 'something went wrong. try again',
          type: 'error',
          dismissible: true,
          timeout: 5000,
        });
      }
    } else {
      try {
        userSigningIn.set(true);
        await signInUser(email, password);
        addToast({
          message: 'welcome back!',
          type: 'success',
          dismissible: true,
          timeout: 5000,
        });
      } catch (error) {
        console.error('signInUser error', error);
        loading = false;
        userSigningIn.set(false);
        addToast({
          message: 'something went wrong. try again',
          type: 'error',
          dismissible: true,
          timeout: 5000,
        });
      }
    }
    loading = false;
  };
</script>

<Flex direction="column">
  <form on:submit|preventDefault={validate}>
    <div>
      <label for={`email_field`}>your email</label>
      <input
        placeholder="your email"
        bind:value={email}
        type="email"
        id={`email_field`}
        class="ot-input"
        maxlength="60"
      />
    </div>
    <VerticalSpacer />
    {#if !checked}
      <div>
        <label for={`password_field`}>your password</label>
        <input
          autocomplete="current-password"
          placeholder="password"
          bind:value={password}
          type="password"
          id={`password_field`}
          class="ot-input"
          maxlength="50"
        />
      </div>
      <VerticalSpacer height="20px" />
      <Button fontSize="medium" iconRight="login" primary>sign in</Button>
      <VerticalSpacer height="20px" />
    {:else}
      <Button fontSize="medium" iconRight="send" primary
        >send password reset</Button
      >
      <VerticalSpacer height="20px" />
    {/if}
    <Flex>
      <label>
        <input bind:checked type="checkbox" class="ot-checkbox" />
        <span class="password-check">forgot password?</span>
      </label>
    </Flex>
  </form>
  {#if loading || $userSigningIn}
    <div class="loading">
      <Loading loading={loading || $userSigningIn} />
    </div>{/if}
</Flex>

<style>
  .password-check {
    font-family: 'Source Code Pro', monospace;
  }
  label {
    font-size: small;
    font-family: 'Source Code Pro', monospace;
  }
  input {
    font-family: 'Source Code Pro', monospace;
    font-size: 16px;
  }
  .loading {
    display: flex;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
  }
</style>
