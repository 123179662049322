<script>
  export let label = "";
  export let value = "";
  export let placeholder = "";
  export let maxlength = "100"
</script>

<div>
  <label for={`${label}_field`}>{label}</label>
  <input
    {placeholder}
    bind:value
    type="text"
    id={`${label}_field`}
    class="ot-input"
    maxlength={maxlength}
  />
</div>

<style>
  label {
    font-size: small;
  }
  input {
    font-family: "Source Code Pro", monospace;
  }
</style>
