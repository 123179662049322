<script>
  import VerticalSpacer from '../components/VerticalSpacer.svelte';
  import {
    Text,
    TextInput,
    Button,
    Divider,
    Badge,
    BoxWithHeading,
    Icon,
    LoadingText,
    Loading,
    Logo,
    ThickBorder,
    LogoLoading,
  } from '../components/ui';
  import DataCard from '../components/DataCard.svelte';
  import RoundSelector from '../components/RoundSelector.svelte';
</script>

<div>
  <Text>UI Library</Text>

  <Divider />

  <LogoLoading />

  <Divider />

  <Text>Buttons</Text>
  <Button primary>primary</Button>
  <Button>standard</Button>
  <Button disabled>disabled</Button>

  <TextInput placeholder="a text input" />

  <Divider />

  <BoxWithHeading heading="a box with a heading">
    <Text>some text</Text>
  </BoxWithHeading>

  <Badge>some text</Badge>

  <Icon />

  <LoadingText />

  <Loading loading={true} />

  <Logo name="question" />

  <VerticalSpacer height="50px" />
  <Divider />
  <VerticalSpacer height="50px" />

  <RoundSelector
    onRoundSelect={() => {
      console.log('round selected');
    }}
  />

  <VerticalSpacer height="50px" />
  <Divider />
  <VerticalSpacer height="50px" />

  <DataCard label="the label" value="100" />
  <VerticalSpacer height="50px" />
  <Divider />

  <Text>Borders</Text>
  <VerticalSpacer height="20px" />
  <ThickBorder padding="10px">
    <Text>some text</Text>
  </ThickBorder>
  <VerticalSpacer height="20px" />
  <ThickBorder borderColor="grey" padding="10px" dropShadowColor="yellow">
    <Text>some text</Text>
  </ThickBorder>
  <VerticalSpacer height="20px" />
  <ThickBorder padding="10px" borderColor="yellow">
    <Text>some text</Text>
  </ThickBorder>
</div>
