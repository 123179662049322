<script>
  import {
    Text,
    Heading,
    VerticalSpacer,
    PageContainer,
  } from '../components/ui';
</script>

<PageContainer>
  <VerticalSpacer height="20px" />
  <Heading>privacy policy</Heading>
  <VerticalSpacer height="10px" />
  <Text fontSize="x-small">Last updated: 4 May 2023</Text>
  <VerticalSpacer height="20px" />
  <Text fontSize="small">
    At Odd Tipping, we value your privacy and are committed to protecting your
    personal information. This privacy policy explains how we collect, use, and
    share information when you use our website.
  </Text>
  <VerticalSpacer height="10px" />
  <Text fontWeight="600" fontSize="small">Information We Collect</Text>
  <Text fontSize="small">
    When you register for an account, we collect your name, email address, and
    any other information you provide to us. We also collect some information
    about your use of the website, such as your tips, usage history, IP address,
    and device information.
  </Text>
  <VerticalSpacer height="10px" />
  <Text fontWeight="600" fontSize="small">How We Use Your Information</Text
  ><Text fontSize="small">
    We use your information to provide you with the services and features of our
    website, to communicate with you about your account, and to send you emails.
    You can unsubscribe from these emails at any time using the unsubscribe
    link.
  </Text>
  <VerticalSpacer height="10px" />
  <Text fontWeight="600" fontSize="small">How We Share Your Information</Text>
  <Text fontSize="small">
    We do not share your personal information with third parties, except as
    necessary to provide you with the services and features of our website. We
    may also share your information if we are required to do so by law.
  </Text>
  <VerticalSpacer height="10px" />
  <Text fontWeight="600" fontSize="small">Your Choices</Text>
  <Text fontSize="small">
    You can choose not to provide us with certain information, but this may
    limit your ability to use certain features of our website. You can also opt
    out of receiving promotional emails from us by following the instructions in
    the emails we send you.
  </Text>
  <VerticalSpacer height="10px" />
  <Text fontWeight="600" fontSize="small">Security</Text>
  <Text fontSize="small">
    We take reasonable measures to protect your personal information from
    unauthorized access, use, or disclosure.
  </Text>
  <VerticalSpacer height="10px" />
  <Text fontWeight="600" fontSize="small">Changes to This Privacy Policy</Text>
  <Text fontSize="small">
    We may update this privacy policy from time to time. If we make significant
    changes to the policy, we will notify you by email or by posting a notice on
    our website.
  </Text>
  <VerticalSpacer height="10px" />
  <Text fontWeight="600" fontSize="small">Contact Us</Text>
  <Text fontSize="small">
    If you have any questions or concerns about our privacy policy, please
    contact us at ryman@oddtipping.com.au.</Text
  >
</PageContainer>

<VerticalSpacer height="20px" />

<style>
</style>
