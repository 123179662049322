<script>
  import { onMount } from 'svelte';
  import {
    Text,
    Flex,
    Button,
    Icon,
    PageContainer,
    LoadingText,
    VerticalSpacer,
    Heading
  } from '../components/ui';
  import {
    viewedUserTipsLastDoc,
    viewedUserTipsStore,
    viewedUserId,
  } from '../stores/stores';
  import { getViewedUserTips } from '../utils/wallets';
  export let id;

  let allowLoadMoreTips = true;
  let tips = null;
  let tipsLastDoc;
  let loading = true;

  viewedUserTipsStore.subscribe((value) => {
    tips = value;
  });

  onMount(async () => {
    tipsLastDoc = await getViewedUserTips(id);
    viewedUserTipsLastDoc.set(tipsLastDoc);
    loading = false;
    viewedUserId.set(id);
  });
</script>

<PageContainer>
  {#if loading}
    <div class="loading-container">
      <LoadingText />
    </div>
  {:else if tips}
  <VerticalSpacer height="20px" />
    <Heading margin="0px 0px 10px 0px">tips history</Heading>
    <table>
      <tr>
        
        <th><Text bodyFont fontWeight="300" fontSize="small">match</Text></th>
        <th class="td-center"
          ><Text bodyFont fontWeight="300" fontSize="small">tip</Text></th
        >
        <th class="td-center"
          ><Text bodyFont fontWeight="300" fontSize="small">stake</Text></th
        >
        <th />
      </tr>
      {#each tips as tip}
        <tr>
          <td>
            <Text bodyFont fontSize="small">{tip.title}</Text>
          </td>
          <td class="td-center">
            <Text bodyFont fontSize="small">{tip.tip}</Text>
          </td>
          <td class="td-center">
            <Text bodyFont fontSize="small"
              >${tip.stake} @ {tip.odds.toFixed(2)}</Text
            >
          </td>
          <td>
            <Icon
              height="15px"
              margin="0px 0px"
              name={tip.settled
                ? tip.winner
                  ? 'coin_check'
                  : 'coin_cross'
                : 'clock'}
            />
          </td>
        </tr>
      {/each}
    </table>
    <Flex justify="center" margin="20px 0px 0px 0px">
      <Button
        disabled={!allowLoadMoreTips || !$viewedUserTipsLastDoc}
        fontSize="small"
        onClick={async () => {
          if (!allowLoadMoreTips || !$viewedUserTipsLastDoc) return;
          let tipsLastDoc = await getViewedUserTips(id, $viewedUserTipsLastDoc);
          if (tipsLastDoc) {
            viewedUserTipsLastDoc.set(tipsLastDoc);
          } else {
            allowLoadMoreTips = false;
          }
        }}>load more</Button
      >
    </Flex>
  {:else}
    <p><Text>No settled tips.</Text></p>
  {/if}
</PageContainer>

<style>
  .loading-container {
    height: 80vh;
    width: 100%;
  }

  table {
    width: 100%;
  }

  td {
    text-align: left;
  }

  .td-center {
    text-align: center;
  }
</style>
